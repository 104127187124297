import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: {},
};

const userSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.admin = payload;
    },

    removeUser: (state) => {
      state.admin = {};
    },
  },
});

export const { removeUser, setUser } = userSlice.actions;

export default userSlice.reducer;
