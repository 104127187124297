import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const SettingsActivityModal = ({ details, onCancel }) => {
  const beforeData = JSON?.parse(details?.before);
  const afterData = JSON?.parse(details?.after);

  return (
    <Grid
      container
      py={6}
      width={"700px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Grid
        item
        width={"80%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#000000",
            fontFamily: "Open-Sans",
          }}
        >
          SETTINGS ACTIVITY
        </Typography>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tbody
            style={{
              margin: "0px auto",
            }}
          >
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-header-data">
                <p>Before</p>
              </td>
              <td className="rule-approval-table-header-data">
                <p>After</p>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5> <p>{beforeData?.Email ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5>
                  <p>{afterData?.Email ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Status</h5>
                  <p>{beforeData?.Status ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Status</h5>
                  <p>{afterData?.Status ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Enable</h5>
                  <p>{beforeData?.Enabled ? "YES" : "NO"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Enable</h5>
                  <p>{afterData?.Enabled ? "YES" : "NO"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Maxumum Failure Rate</h5>
                  <p>{beforeData?.MaxFailureRate ?? "-"}%</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Maxumum Failure Rate</h5>
                  <p>{afterData?.MaxFailureRate ?? "-"}%</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Warning Trigger Rate</h5>
                  <p>{beforeData?.WarningTriggerRate ?? "-"}%</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Warning Trigger Rate</h5>
                  <p>{afterData?.WarningTriggerRate ?? "-"}%</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Review Window</h5>
                  <p className="rule-approval-date">
                    {beforeData?.ReviewWindow ?? "-"} Hrs
                  </p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Review Window</h5>
                  <p className="rule-approval-date">
                    {afterData?.ReviewWindow ?? "-"} Hrs
                  </p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Date Created</h5>{" "}
                  <p className="rule-approval-date">
                    {beforeData?.CreatedAt?.substr(0, 10) ?? "-"}
                  </p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Date Created</h5>
                  <p className="rule-approval-date">
                    {afterData?.CreatedAt?.substr(0, 10) ?? "-"}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {details?.rejectionReason !== null ||
          (details?.rejectionReason !== undefined && (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "36px",
                  marginRight: "4px",
                }}
              >
                Rejection Reason:
              </Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
                {details?.rejectionReason}
              </Typography>
            </Box>
          ))}
        <Box
          paddingTop={"40px"}
          display={"flex"}
          justifyContent={"space-around"}
          columnGap={"20px"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#255CFF",
              textTransform: "capitalize",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255CFF",
                color: "white",
                borderColor: "white",
              },
            }}
            onClick={() => {
              onCancel(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SettingsActivityModal;
