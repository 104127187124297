import React from "react";
import { alpha, styled } from "@mui/material/styles";
import { deepOrange } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import TimerIcon from "@mui/icons-material/Timer";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import "../../../styles/ruleConfig.css";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import SettlementSection from "../../SettlementSection/VendorSettlement";
// import { useNavigate } from 'react-router-dom';
// import TimeCard from './TimeCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: deepOrange[600],
    "&:hover": {
      backgroundColor: alpha(
        deepOrange[600],
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: deepOrange[600],
  },
}));
const label = { inputProps: { "aria-label": "Not connected" } };

const TimeSection = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dashboard-container">
      <div style={{ width: "94%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h2 className="dash-header">Add Routing Rule</h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div>
              <div style={{ display: "flex" }}>
                <GreenSwitch {...label} defaultChecked />
                <p style={{ marginTop: "6px" }} className="form-control-label">
                  Connected
                </p>
              </div>
            </div>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss a")}</p>
            </div>
            <div>
              <Box sx={{ color: "action.active", marginTop: "4px" }}>
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent=""
                  variant="dot"
                >
                  <NotificationsIcon />
                </Badge>
              </Box>
            </div>
          </div>
        </div>

        <div>
          <p className="ruleConfig-header">
            Configure routing rules to automatically allow system detect
            customer actions by enabling the set configured rules
          </p>
        </div>

        <div className="ruleConfig-tabs">
          <Box sx={{ width: "85%", margin: "0 auto" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                sx: { backgroundColor: "#FF543D", height: 4 },
              }}
              sx={{
                "& button:active": { color: "#FF543D" },
                "& button.Mui-selected": { color: "#FF543D" },
              }}
            >
              <Tab label="Rule Configuration" {...a11yProps(0)} />
              <Tab label="Vendors" {...a11yProps(1)} />
              <Tab label="Settlement" {...a11yProps(2)} />
            </Tabs>
          </Box>
        </div>

        <Box sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <button
              type="submit"
              className="rule-btn"
              style={{ marginTop: "45px", marginBottom: "26px" }}
            >
              <div className="btn-container">
                <span>
                  <AddCircleOutlinedIcon />
                </span>
                ADD NEW RULE
              </div>
            </button>

            {/* <TimeCard /> */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <button
              type="submit"
              className="rule-btn"
              style={{ marginTop: "45px", marginBottom: "26px", width: "18%" }}
            >
              <div className="btn-container">
                <span>
                  <AddCircleOutlinedIcon />
                </span>
                ADD NEW VENDORS
              </div>
            </button>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <button
              type="submit"
              className="rule-btn"
              style={{ marginTop: "45px", marginBottom: "26px", width: "20%" }}
            >
              <div className="btn-container">
                <span>
                  <AddCircleOutlinedIcon />
                </span>
                ADD NEW SETTLEMENTS
              </div>
            </button>
            <SettlementSection />
          </TabPanel>
        </Box>
      </div>

      {/* <RuleConfig /> */}
    </div>
  );
};

export default TimeSection;
