import "../styles/error.css";
import logo403 from "../assets/svg/logo403.svg";

const Error403 = () => {
  return (
    <div className="error-container">
      <img src={logo403} alt="Error 403" />
      <h2 className="error-header" style={{ marginTop: "50px" }}>
        Forbidden
      </h2>
      <p className="error-sub-header">
        Sorry, you do not have permission to access this page
      </p>
    </div>
  );
};

export default Error403;
