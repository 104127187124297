import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "../../../styles/ruleConfig.css";
import { rulesService } from "../../../utils/api";
import { toast } from "react-toastify";
import { useUser } from "../../../utils/hooks/redux.hook";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Waiting from "../../../assets/svg/waiting.svg";

const BankCheckbox = () => {
  const { admin } = useUser();
  const [checkedItems, setCheckedItems] = useState({});
  const [rules, setRules] = useState([]);

  const fetchChannelRules = async () => {
    try {
      const response = await rulesService.getChannelRules();
      setRules(response?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchChannelRules();
  }, []);

  const handleSave = async (rule) => {
    const splitObject = {};

    Object.keys(checkedItems).forEach((key) => {
      const [parentKey, childKey] = key.split("+");
      if (!splitObject[parentKey]) {
        splitObject[parentKey] = {};
      }
      splitObject[parentKey][childKey] = checkedItems[key];
    });

    const payload = {
      ...rule,
      ...splitObject[rule?.id],
      email: admin?.admin?.email,
    };

    try {
      const response = await rulesService.updateChannelRule(rule?.id, payload);
      if (response?.status === 200) {
        toast.success("Rule sent for approval", {
          className: "toast-success",
          theme: "colored",
        });
        setCheckedItems({});
        fetchChannelRules();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {}, [checkedItems]);

  function handleCheckbox(event) {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  }

  return (
    <div className="ruleSec-formControl">
      <div>
        {rules?.map((vendor, index) => (
          <Box sx={{ my: 8 }} key={index}>
            <FormControl
              sx={{
                color: "#000",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              component="fieldset"
              variant="standard"
            >
              <FormGroup
                sx={{
                  paddingLeft: "5px",
                  fontFamily: "Open-Sans",
                }}
              >
                {vendor?.name}
              </FormGroup>

              {/* network checkboxes */}
              <FormGroup sx={{ flexDirection: "row", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"internet"}
                      name={vendor?.vendorId + "+internet"}
                      checked={
                        checkedItems[vendor?.vendorId + "+internet"] ??
                        vendor?.internet
                      }
                      onChange={(e) => handleCheckbox(e)}
                      sx={{
                        color: "#255cff",
                        fontFamily: "Open-Sans",
                        "&.Mui-checked": {
                          color: "#255cff",
                        },
                      }}
                    />
                  }
                  label="INTERNET"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={"mobile"}
                      name={vendor?.vendorId + "+mobile"}
                      checked={
                        checkedItems[vendor?.vendorId + "+mobile"] ??
                        vendor?.mobile
                      }
                      onChange={(e) => handleCheckbox(e)}
                      sx={{
                        color: "#255cff",
                        fontFamily: "Open-Sans",
                        "&.Mui-checked": {
                          color: "#255cff",
                        },
                      }}
                    />
                  }
                  label="MOBILE"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={"atm"}
                      name={vendor?.vendorId + "+atm"}
                      checked={
                        checkedItems[vendor?.vendorId + "+atm"] ?? vendor?.atm
                      }
                      onChange={(e) => handleCheckbox(e)}
                      sx={{
                        color: "#255cff",
                        fontFamily: "Open-Sans",
                        "&.Mui-checked": {
                          color: "#255cff",
                        },
                      }}
                    />
                  }
                  label="ATM"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"ussd"}
                      name={vendor?.vendorId + "+ussd"}
                      checked={
                        checkedItems[vendor?.vendorId + "+ussd"] ?? vendor?.ussd
                      }
                      onChange={(e) => handleCheckbox(e)}
                      sx={{
                        color: "#255cff",
                        fontFamily: "Open-Sans",
                        "&.Mui-checked": {
                          color: "#255cff",
                        },
                      }}
                    />
                  }
                  label="USSD"
                />
                {vendor?.status === "PENDING" ? (
                  <img
                    src={Waiting}
                    alt="Waiting Icon"
                    style={{ marginLeft: "6px", marginRight: "12px" }}
                  />
                ) : (
                  <div style={{ width: "42px" }}></div>
                )}
                <button
                  className="ruleSec-reset-btn"
                  disabled={
                    vendor?.status === "PENDING" ||
                    ((!vendor?.internet !==
                      checkedItems[vendor?.vendorId + "+internet"] ??
                      vendor?.internet) &&
                      (!vendor?.mobile !==
                        checkedItems[vendor?.vendorId + "+mobile"] ??
                        vendor?.mobile) &&
                      (!vendor?.atm !==
                        checkedItems[vendor?.vendorId + "+atm"] ??
                        vendor?.atm) &&
                      (!vendor?.ussd !==
                        checkedItems[vendor?.vendorId + "+ussd"] ??
                        vendor?.ussd))
                  }
                  onClick={() => handleSave(vendor)}
                >
                  Save
                </button>
              </FormGroup>
            </FormControl>
          </Box>
        ))}
      </div>
      <div>
        <NavLink
          to="/admin/rule-history"
          className="rule-config-history"
          activeclassname="active"
        >
          <p>Rule Change History</p>
          <ArrowForwardIosIcon />
        </NavLink>
      </div>
    </div>
  );
};

export default BankCheckbox;
