import React, { useState, useEffect } from "react";

import { Card, Typography } from "@mui/material";

import { settlementService } from "../../utils/api";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const History = () => {
  const [history, setHistory] = useState([]);
  const [loadCount, setLoadCount] = useState(10);
  const [cardHeight, setCardHeight] = useState({});
  const [isCardOpen, setIsCardOpen] = useState({});

  const fetchHistory = async () => {
    try {
      const response = await settlementService.getVendorSettlements();
      setHistory(response?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const allHistory = history?.sort((a, b) => b.id - a.id)?.slice(0, loadCount);

  const updateCardState = (itemId, height, bol) => {
    setCardHeight((prevHeight) => ({ ...prevHeight, [itemId]: height }));
    setIsCardOpen((prevVal) => ({ ...prevVal, [itemId]: bol }));
  };

  const nairaFormatter = (amount) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter?.format(amount);
  };
  return (
    <Card
      sx={{
        width: "91%",
        padding: "35px 60px 35px 24px",
        boxShadow: "none",
        borderRadius: "20px",
      }}
    >
      <div>
        {allHistory?.map((item) => (
          <Card
            key={item.id}
            sx={{
              height: cardHeight[item.id] || "201px",
              marginBottom: "45px",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
                borderLeft: "1px dashed #D9D9D9",
              }}
            >
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isCardOpen[item.id] ? (
                  <ArrowDropDownIcon
                    sx={{
                      color: "#255cff",
                      mr: "16px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      updateCardState(item.id, "201px", false);
                    }}
                  />
                ) : (
                  <ArrowRightIcon
                    sx={{
                      color: "#255cff",
                      mr: "14px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      updateCardState(item.id, "770px", true);
                    }}
                  />
                )}

                <Typography
                  sx={{
                    color: "#000000",
                    margin: "0px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Changes made on {item?.VendorName}, on{" "}
                  {item.createdAt?.substr(0, 10)}
                  {/* <span style={{ color: "#FF543D" }}>{item?.createdBy}</span> */}
                </Typography>
              </div>
              <table
                style={{
                  width: "100%",
                  marginLeft: "45px",
                  borderCollapse: "collapse",
                }}
              >
                <tbody
                  style={{
                    margin: "0px auto",
                  }}
                >
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>ID</h5> <p>{item?.id}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Status</h5>
                        <p
                          style={{
                            color:
                              item.settlementStatus === "PENDING"
                                ? "#FF9515"
                                : item.settlementStatus === "APPROVED" ||
                                  item.settlementStatus === "INITIATED"
                                ? "#04CA00"
                                : "#FF5B5B",
                          }}
                        >
                          {item?.settlementStatus}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Vendor Name</h5> <p>{item?.VendorName}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Vendor Account</h5> <p>{item?.VendorAccount}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Network Name</h5>
                        <p>{item?.NetworkName ?? "-"}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Dealer Receivable</h5>
                        <p>{nairaFormatter(item?.DealerReceivable)}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Success Rate</h5>
                        <p>{item?.successRate}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Processed</h5>
                        <p
                          style={{
                            color: item?.processed ? "#04CA00" : "#FF5B5B",
                          }}
                        >
                          {item?.processed?.toString()}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Bank Commission</h5>
                        <p>{nairaFormatter(item?.BankCommission)}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Bank Income Account</h5>
                        <p>{item?.BankIncomeAccount}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Platform Commission</h5>
                        <p>{nairaFormatter(item?.PlatformCommission)}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Platform Account</h5>
                        <p>{item?.PlatformAccount}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Total Successful</h5>
                        <p>{item?.TotalSuccessful}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Total Successful</h5>
                        <p>{nairaFormatter(item?.TotalSuccessAmount)}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Total Failed</h5>
                        <p>{item?.TotalFailed}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Total Failed Amount</h5>
                        <p>{nairaFormatter(item?.TotalFailedAmount)}</p>
                      </div>
                    </td>
                  </tr>

                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Settlement Date</h5>
                        <p>{item?.SettlementDate?.substr(0, 10) ?? "-"}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Created At</h5>
                        <p>{item?.createdAt?.substr(0, 10) ?? "-"}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Approved At</h5>
                        <p>{item?.approvedAt?.substr(0, 10) ?? "-"}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        ))}
      </div>
      <button
        onClick={() => setLoadCount((prev) => prev + 10)}
        disabled={loadCount > allHistory?.length}
        className="rule-history-button"
        style={{ display: allHistory?.length < 10 && "none" }}
      >
        <AccessTimeIcon sx={{ marginRight: "6px" }} /> Load Older Changes
      </button>
    </Card>
  );
};

export default History;
