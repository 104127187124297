import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import verified from "../../assets/svg/verify.svg";
import error from "../../assets/svg/close.png";
import "../../styles/verify.css";
import { authService } from "../../utils/api";

const ActivationPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState("");
  const [success, setSuccess] = useState(null);
  //
  useEffect(() => {
    activate();
  }, [token]);
  //activate account
  const activate = async () => {
    let payload = {
      activation_code: token,
    };
    setLoading(true);
    try {
      const data = await authService.activate(payload);
      if (data?.status === 200) {
        setSuccess("success");
        setLoading(false);
        toast.success("Authentication successful.", {
          className: "toast-success",
          theme: "colored",
        });
      } else {
        setSuccess("error");
      }
    } catch (error) {
      setSuccess("error");
      toast.success("Authentication Failed.", {
        className: "toast-success",
        theme: "colored",
      });

      setLoading(false);
    }
    setLoading(false);
  };

  const handleRedirect = () => {
    window.location = "/auth/login";
  };
  console.log(success);
  const Error = () => {
    return (
      <div className="verify">
        <img src={error} alt="error" width={100} />

        <div className="verify-content">
          <h6>Something Went Wrong</h6>
          <p>There was an error in activating your acount</p>
          <button type="submit" onClick={handleRedirect}>
            {" "}
            Proceed to Login
          </button>
        </div>
      </div>
    );
  };

  const Success = () => {
    return (
      <div className="verify">
        <img src={verified} alt="verified" />

        <div className="verify-content">
          <h6>Your email has been verified</h6>
          <p>
            Now you can access our awesome services. We will also use this email
            to communicate with you when necessary
          </p>

          <button type="submit" onClick={handleRedirect}>
            {" "}
            Proceed to Login
          </button>
        </div>
      </div>
    );
  };

  const Activating = () => {
    return (
      <div className="verify">
        <div className="verify-content">
          <p>Activating User Please wait.......</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading && <Activating />}

      {success === "success" && <Success />}
      {success === "error" && <Error />}
    </div>
  );
};

export default ActivationPage;
