import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { userService } from "../../utils/api";

import { Card } from "@mui/material";
const EditUser = ({ userById, setOpenEditUser, fetchUsers }) => {
  const creatorEmail = JSON.parse(sessionStorage.getItem("user")).email;
  const [formErrors, setFormErrors] = useState({});
  const [roles, setRoles] = useState([]);

  const [userDetailsInput, setUserDetailsInput] = useState({
    firstName: userById.firstName,
    lastName: userById.lastName,
    email: userById.email,
    username: userById.username,
    userType: userById.userType,
    disabled: userById.disabled,
    editorEmail: creatorEmail,
    id: userById.id,
  });

  const validate = (values) => {
    // validate(formValues)
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!values.username) {
      errors.username = " Username is required";
    }

    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values.userType) {
      errors.userType = "Please select a role for this user";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetailsInput({
      ...userDetailsInput,
      [name]: value === "true" || value === "false" ? JSON.parse(value) : value,
    });
  };

  const fetchRoles = async () => {
    try {
      const response = await userService.allRoles();
      setRoles(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(userDetailsInput));
    try {
      const response = await userService.updateUser(userDetailsInput);

      if (response?.status === 200) {
        toast.success(
          "User details updated successfully, waiting for approval.",
          {
            className: "toast-success",
            theme: "colored",
          }
        );
        setOpenEditUser(false);
        fetchUsers();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  return (
    <Card
      sx={{
        background: "#F3F5FF",
        width: "100%",
        paddingTop: "10px",
        boxShadow: "none",
      }}
    >
      <div className="user-table-header">
        <div className="user-table-header-div">
          <h2 className="user-table-header-text"> Update User</h2>
        </div>
      </div>
      <form className="new-user-form" onSubmit={handleSubmit}>
        <div className="new-user-form-input-cont">
          <label htmlFor="userName" className="new-user-form-label">
            Username
          </label>
          <div>
            <input
              readOnly
              type="text"
              name="username"
              value={userDetailsInput.username}
              onChange={handleChange}
              className="new-user-form-input"
            />
            <p style={{ color: "red" }}>{formErrors.username}</p>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", columnGap: "39px" }}>
          <div className="new-user-form-input-cont">
            <label htmlFor="firstName" className="new-user-form-label">
              First Name
            </label>
            <div>
              <input
                type="text"
                name="firstName"
                value={userDetailsInput.firstName}
                onChange={handleChange}
                className="new-user-form-input"
              />
              <p style={{ color: "red" }}>{formErrors.firstName}</p>
            </div>
          </div>
          <div className="new-user-form-input-cont">
            <label htmlFor="lasttName" className="new-user-form-label">
              Last Name
            </label>
            <div>
              <input
                type="text"
                name="lastName"
                value={userDetailsInput.lastName}
                onChange={handleChange}
                className="new-user-form-input"
              />
              <p style={{ color: "red" }}>{formErrors.lastName}</p>
            </div>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="email" className="new-user-form-label">
            Email
          </label>
          <div>
            <input
              readOnly
              type="email"
              name="email"
              value={userDetailsInput.email}
              onChange={handleChange}
              className="new-user-form-input"
            />
            <p style={{ color: "red" }}>{formErrors.email}</p>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="firstName" className="new-user-form-label">
            Role
          </label>
          <div>
            <select
              name="userType"
              id=""
              value={userDetailsInput.userType}
              onChange={handleChange}
              className="new-user-form-input"
            >
              <option selected>Select role</option>
              {roles?.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>

            <p style={{ color: "red" }}>{formErrors.userType}</p>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="firstName" className="new-user-form-label">
            Disabled
          </label>
          <div>
            <select
              name="disabled"
              id=""
              value={userDetailsInput.disabled}
              onChange={handleChange}
              className="new-user-form-input"
            >
              {[true, false].map((item) => (
                <option key={item} value={item}>
                  {item ? "True" : "False"}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="new-user-form-button-footer">
          <p className="new-user-form-text">
            Please provide the correct Information required.
          </p>
          <div className="new-user-form-button-cont">
            <button
              type="button"
              onClick={() => setOpenEditUser(false)}
              style={{
                background: "#fff",
                color: "#255CFF",
                border: "1px solid #255CFF",
              }}
              className="new-user-form-button"
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{
                background: "#255CFF",
                color: "#ffffff",
              }}
              className="new-user-form-button"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default EditUser;
