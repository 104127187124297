import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "../../../styles/ruleConfig.css";
import { rulesService } from "../../../utils/api";
import { toast } from "react-toastify";
import { useUser } from "../../../utils/hooks/redux.hook";
import { TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Waiting from "../../../assets/svg/waiting.svg";

const CheckboxSec = () => {
  const { admin } = useUser();

  const [rules, setRules] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  const fetchNetworkRules = async () => {
    try {
      const response = await rulesService.getNetworkRules();
      setRules(response?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchNetworkRules();
  }, []);

  const handleSave = async (rule) => {
    //   const filterCheckedItems = Object.keys(checkedItems)
    //   .filter(item => item?.split(/\+/)[0] === rule?.vendorId)
    //   .reduce((obj, key) => {
    //     obj[key] = checkedItems[key];
    //     return obj;
    //   }, {});
    // console.log(filterCheckedItems)
    const splitObject = {};

    Object.keys(checkedItems).forEach((key) => {
      const [parentKey, childKey] = key.split("+");
      if (!splitObject[parentKey]) {
        splitObject[parentKey] = {};
      }
      splitObject[parentKey][childKey] = checkedItems[key];
    });

    const payload = {
      ...rule,
      ...splitObject[rule?.id],
      email: admin?.admin?.email,
    };

    try {
      const response = await rulesService.updateNetworkRule(rule?.id, payload);

      if (response?.status === 200) {
        toast.success("Rule sent for approval", {
          className: "toast-success",
          theme: "colored",
        });
        setCheckedItems({});
        fetchNetworkRules();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {}, [checkedItems]);

  function handleCheckbox(event) {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  }

  function handleChange(event) {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.value,
    });
  }

  const handleNegativeInput = (event) => {
    return (event.target.value = Math.abs(event.target.value));
  };

  return (
    <div className="ruleSec-formControl">
      <div>
        {rules?.map((vendor, index) => (
          <Box
            sx={{
              paddingLeft: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#ffffff",
            }}
            key={index}
          >
            <FormControl
              sx={{
                width: "100%",
                height: "130px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              component="fieldset"
              variant="standard"
            >
              <FormGroup
                sx={{
                  paddingLeft: "5px",
                  fontFamily: "Open-Sans",
                }}
              >
                {vendor?.name}
              </FormGroup>

              {/* network checkboxes */}
              <FormGroup
                sx={{
                  width: "680px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div className="rule-config-checkbox-cont">
                  <div className="rule-config-checkbox-name-cont">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"mtn"}
                          name={vendor?.id + "+mtn"}
                          checked={
                            checkedItems[vendor?.id + "+mtn"] ?? vendor?.mtn
                          }
                          onChange={(e) => handleCheckbox(e)}
                          sx={{
                            color: "#FFD953",
                            "&.Mui-checked": {
                              color: "#FFD953",
                            },
                          }}
                        />
                      }
                    />
                    <label
                      htmlFor=""
                      style={{ background: "#FFD953" }}
                      className="rule-config-checkbox-name-label"
                    >
                      MTN
                    </label>
                  </div>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    type="number"
                    value={
                      checkedItems[vendor?.id + "+mtnRate"] ??
                      vendor?.mtnRate ??
                      0
                    }
                    name={vendor?.id + "+mtnRate"}
                    onInput={handleNegativeInput}
                    onChange={handleChange}
                    sx={{ width: "55px" }}
                    inputProps={{
                      style: {
                        padding: "3px 5px",
                      },
                    }}
                  />
                </div>
                <div className="rule-config-checkbox-cont">
                  <div className="rule-config-checkbox-name-cont">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"airtel"}
                          name={vendor?.id + "+airtel"}
                          checked={
                            checkedItems[vendor?.id + "+airtel"] ??
                            vendor?.airtel
                          }
                          onChange={(e) => handleCheckbox(e)}
                          sx={{
                            color: "#FF543D",
                            "&.Mui-checked": {
                              color: "#FF543D",
                            },
                          }}
                        />
                      }
                    />
                    <label
                      htmlFor=""
                      style={{ background: "#FF543D", color: "#fff" }}
                      className="rule-config-checkbox-name-label"
                    >
                      AIRTEL
                    </label>
                  </div>
                  <TextField
                    id="outlined-basic"
                    label=""
                    type="number"
                    value={
                      checkedItems[vendor?.id + "+airtelRate"] ??
                      vendor?.airtelRate ??
                      0
                    }
                    name={vendor?.id + "+airtelRate"}
                    onInput={handleNegativeInput}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{
                      width: "55px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 5px",
                      },
                    }}
                  />
                </div>
                <div className="rule-config-checkbox-cont">
                  <div className="rule-config-checkbox-name-cont">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"glo"}
                          name={vendor?.id + "+glo"}
                          checked={
                            checkedItems[vendor?.id + "+glo"] ?? vendor?.glo
                          }
                          onChange={(e) => handleCheckbox(e)}
                          sx={{
                            color: "#72F651",
                            "&.Mui-checked": {
                              color: "#72F651",
                            },
                          }}
                        />
                      }
                    />
                    <label
                      htmlFor=""
                      style={{ background: "#72F651" }}
                      className="rule-config-checkbox-name-label"
                    >
                      GLO
                    </label>
                  </div>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    type="number"
                    value={
                      checkedItems[vendor?.id + "+gloRate"] ??
                      vendor?.gloRate ??
                      0
                    }
                    name={vendor?.id + "+gloRate"}
                    onInput={handleNegativeInput}
                    onChange={handleChange}
                    sx={{ width: "55px" }}
                    inputProps={{
                      style: {
                        padding: "3px 5px",
                      },
                    }}
                  />
                </div>

                <div className="rule-config-checkbox-cont">
                  <div className="rule-config-checkbox-name-cont">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"nineMobile"}
                          name={vendor?.id + "+nineMobile"}
                          checked={
                            checkedItems[vendor?.id + "+nineMobile"] ??
                            vendor?.nineMobile
                          }
                          onChange={(e) => handleCheckbox(e)}
                          sx={{
                            color: "#3C8702",
                            "&.Mui-checked": {
                              color: "#3C8702",
                            },
                          }}
                        />
                      }
                    />
                    <label
                      htmlFor=""
                      style={{ background: "#3C8702", color: "#fff" }}
                      className="rule-config-checkbox-name-label"
                    >
                      9MOBILE
                    </label>
                  </div>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    label=""
                    value={
                      checkedItems[vendor?.id + "+nineMobileRate"] ??
                      vendor?.nineMobileRate ??
                      0
                    }
                    name={vendor?.id + "+nineMobileRate"}
                    onInput={handleNegativeInput}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: "55px" }}
                    inputProps={{
                      style: {
                        padding: "3px 5px",
                      },
                    }}
                  />
                </div>
                {vendor?.status === "PENDING" ? (
                  <img
                    src={Waiting}
                    alt="Waiting Icon"
                    style={{ marginLeft: "10px", marginRight: "4px" }}
                  />
                ) : (
                  <div style={{ width: "34px" }}></div>
                )}
                <button
                  className="ruleSec-reset-btn"
                  style={{ marginLeft: "10px" }}
                  disabled={
                    vendor?.status === "PENDING" ||
                    ((!vendor?.airtel !==
                      checkedItems[vendor?.id + "+airtel"] ??
                      vendor?.airtel) &&
                      (!vendor?.glo !== checkedItems[vendor?.id + "+glo"] ??
                        vendor?.glo) &&
                      (!vendor?.mtn !== checkedItems[vendor?.id + "+mtn"] ??
                        vendor?.mtn) &&
                      (!vendor?.nineMobile !==
                        checkedItems[vendor?.id + "+nineMobile"] ??
                        vendor?.nineMobile) &&
                      (!checkedItems[vendor?.id + "+airtelRate"] ||
                        vendor?.airtelRate ===
                          parseInt(checkedItems[vendor?.id + "+airtelRate"])) &&
                      (!checkedItems[vendor?.id + "+gloRate"] ||
                        vendor?.gloRate ===
                          parseInt(checkedItems[vendor?.id + "+gloRate"])) &&
                      (!checkedItems[vendor?.id + "+mtnRate"] ||
                        vendor?.mtnRate ===
                          parseInt(checkedItems[vendor?.id + "+mtnRate"])) &&
                      (!checkedItems[vendor?.id + "+airtelRate"] ||
                        vendor?.airtelRate ===
                          parseInt(checkedItems[vendor?.id + "+airtelRate"])))
                  }
                  onClick={() => handleSave(vendor)}
                >
                  Save
                </button>
              </FormGroup>
            </FormControl>
          </Box>
        ))}
      </div>
      <div>
        <NavLink
          to="/admin/rule-history"
          className="rule-config-history"
          activeclassname="active"
        >
          <p>Rule Change History</p>
          <ArrowForwardIosIcon />
        </NavLink>
      </div>
      {/* <div className='ruleSec-btn-container'>
            <button className='ruleSec-btn' type='submit'>Edit</button>
            <button className='ruleSec-reset-btn' onClick={handleSave}>Save</button>

        </div> */}
    </div>
  );
};

export default CheckboxSec;
