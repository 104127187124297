import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const SettingsModal = ({ details, onConfirm, setIsRejectOpen, onCancel }) => {
  const actorEmail = JSON?.parse(sessionStorage.getItem("user")).email;
  const currentData = JSON?.parse(details?.current);
  const proposedData = JSON?.parse(details?.proposed);

  return (
    <Grid
      container
      py={6}
      width={"700px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Grid
        item
        width={"80%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#000000",
            fontFamily: "Open-Sans",
          }}
        >
          SETTINGS APPROVAL
        </Typography>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tbody
            style={{
              margin: "0px auto",
            }}
          >
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-header-data">
                <p>Current</p>
              </td>
              <td className="rule-approval-table-header-data">
                <p>Proposed</p>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5> <p>{currentData?.Email ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5>
                  <p>{proposedData?.Email ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Status</h5>
                  <p>{currentData?.Status ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Status</h5>
                  <p>{proposedData?.Status ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Enable</h5>
                  <p>{currentData?.Enabled ? "YES" : "NO"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Enable</h5>
                  <p>{proposedData?.Enabled ? "YES" : "NO"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Maxumum Failure Rate</h5>
                  <p>{currentData?.MaxFailureRate ?? "-"}%</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Maxumum Failure Rate</h5>
                  <p>{proposedData?.MaxFailureRate ?? "-"}%</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Warning Trigger Rate</h5>
                  <p>{currentData?.WarningTriggerRate ?? "-"}%</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Warning Trigger Rate</h5>
                  <p>{proposedData?.WarningTriggerRate ?? "-"}%</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Review Window</h5>
                  <p className="rule-approval-date">
                    {currentData?.ReviewWindow ?? "-"} Hrs
                  </p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Review Window</h5>
                  <p className="rule-approval-date">
                    {proposedData?.ReviewWindow ?? "-"} Hrs
                  </p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Date Created</h5>{" "}
                  <p className="rule-approval-date">
                    {currentData?.CreatedAt?.substr(0, 10) ?? "-"}
                  </p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Date Created</h5>
                  <p className="rule-approval-date">
                    {proposedData?.CreatedAt?.substr(0, 10) ?? "-"}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {details?.rejectionReason !== null && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{ fontSize: "16px", lineHeight: "36px", marginRight: "4px" }}
            >
              Rejection Reason:
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
              {details?.rejectionReason}
            </Typography>
          </Box>
        )}
        <Box
          py={2}
          display={details?.approvalStatus === "PENDING" ? "flex" : "none"}
          justifyContent={"space-around"}
          columnGap={"20px"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#fff",
              textTransform: "capitalize",
              color: "#255cff",
              fontFamily: "Open-Sans",
              border: "2px solid #255cff",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#B3B3B3",
              },
            }}
            onClick={() => {
              setIsRejectOpen(true);
              onCancel(false);
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#255cff",
              textTransform: "capitalize",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255cff",
              },
            }}
            onClick={() => {
              onConfirm(actorEmail, details?.id);
              onCancel(false);
            }}
          >
            Approve
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SettingsModal;
