import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { lightGreen } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: lightGreen[600],
    '&:hover': {
      backgroundColor: alpha(lightGreen[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: lightGreen[600],
  },
}));
const label = { inputProps: { 'aria-label': 'Not connected'} };



const SwitchConnect = () => {
  return (
    <div style={{ display: 'flex' }}>
        <GreenSwitch
        {...label}
        disabled 
      />
      <p  style={{marginTop: '6px'}} className='form-control-label'> Not Connected</p>
    </div>
  )
}

export default SwitchConnect
