// Library methods
import React, { useState } from "react";
import moment from "moment";

//icons
import TimerIcon from "@mui/icons-material/Timer";

// Components

import "../../styles/users.css";
import AddDocument from "./AddForm";

const Upload = () => {
  const [openAddUser, setOpenAddUser] = useState(false);

  return (
    <div className="dashboard-container">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="user-header-title-div">
              <h2 className="user-header-title-text">Upload Document</h2>
            </div>

            <div style={{ display: "flex" }}>
              <div className="time">
                <TimerIcon />
                <p>Time: {moment().format("h:mm:ss a")}</p>
              </div>
            </div>
          </div>
        </div>

        <AddDocument />
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
    </div>
  );
};

export default Upload;
