import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

const NetworkActivityModal = ({ details, onCancel }) => {
  const beforeData = JSON?.parse(details?.before);
  const afterData = JSON?.parse(details?.after);

  return (
    <Grid
      container
      py={2}
      width={"700px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Grid
        item
        width={"80%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#000000",
            fontFamily: "Open-Sans",
          }}
        >
          NETWORK RULE ACTIVITY
        </Typography>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tbody
            style={{
              margin: "0px auto",
            }}
          >
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-header-data">
                <p>Before</p>
              </td>
              <td className="rule-approval-table-header-data">
                <p>After</p>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5> <p>{beforeData?.Email ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5>
                  <p>{afterData?.Email ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Vendor</h5> <p>{beforeData?.Name ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Vendor</h5>
                  <p>{afterData?.Name ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN</h5>{" "}
                  <p>{beforeData?.Mtn ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN</h5>
                  <p>{afterData?.Mtn ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN Rate</h5>
                  <p>{beforeData?.MtnRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN Rate</h5>
                  <p>{afterData?.MtnRate ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL</h5>
                  <p>{beforeData?.Airtel ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL</h5>
                  <p>{afterData?.Airtel ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL Rate</h5>
                  <p>{beforeData?.AirtelRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL Rate</h5>
                  <p>{afterData?.AirtelRate ?? "-"}</p>
                </div>
              </td>
            </tr>

            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO</h5>
                  <p>{beforeData?.Glo ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO</h5>
                  <p>{afterData?.Glo ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO Rate</h5>
                  <p>{beforeData?.GloRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO Rate</h5>
                  <p>{afterData?.GloRate ?? "-"}</p>
                </div>
              </td>
            </tr>

            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE</h5>
                  <p>{beforeData?.NineMobile ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE</h5>
                  <p>{afterData?.NineMobile ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE Rate</h5>
                  <p>{beforeData?.NineMobileRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE Rate</h5>
                  <p>{afterData?.NineMobileRate ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont"></div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Date</h5>
                  <p className="rule-approval-date">
                    {details?.eventTime?.substr(0, 10) ?? "-"}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Box
          paddingTop={"40px"}
          display={"flex"}
          justifyContent={"space-around"}
          columnGap={"20px"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#255CFF",
              textTransform: "capitalize",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255CFF",
                color: "white",
                borderColor: "white",
              },
            }}
            onClick={() => {
              onCancel(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NetworkActivityModal;
