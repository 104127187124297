import React from 'react';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
// import ruleSecBg from '../../../../assets/svg/ruleSecBg.svg'

const RuleSection = () => {
  return (
    <div>
    <div className="rule-container">
        <div className="rule-header">
            <h1>Rules</h1>
            <button type='submit' className='rule-btn'>
            <div className='btn-container'> 
            <span> <AddCircleOutlinedIcon /> </span>  ADD NEW RULE
              </div>
            </button>
        </div>

        <div className='rule-single-card'>
          <div className='ruleSecImg'>
            <div>
            <div className='rule-text'> 
                <h1 className='card-rule-header'>No Active Rule Yet</h1>
                <h4 className='rule-subheader'>Add a new  routing rules to automatically allow system detect customer actions by enabling the set configured rules</h4>
                <button type='submit' className='rule-btn rule-active-btn'>
                    <div className='btn-active-container'> 
                    <span> <AddCircleOutlinedIcon style={{color: 'white'}} /> </span>  ADD NEW RULE
                    </div>
                </button>
            </div>
            </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default RuleSection
