import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = sessionStorage.getItem("username");
  return user;
};

const AuthProtected = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="auth/login" />;
};

export default AuthProtected;
