// import axios from 'axios';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { vendorService } from "../../../utils/api";
import { Card, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import mail from "../../../assets/svg/mail.svg";
import phone from "../../../assets/svg/phone.svg";
import location from "../../../assets/svg/location.svg";
import active from "../../../assets/svg/active.svg";
import inactive from "../../../assets/svg/inactive.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

//import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

const VendorList = () => {
  const [vendorInfo, setVendorInfo] = useState([]);
  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  const fetchData = async () => {
    const { data } = await vendorService.getAllVendors();
    setVendorInfo(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEnableVendor = async (actorEmail, vendorId) => {
    try {
      await vendorService.enableVendor(actorEmail, vendorId);
      toast.success("Request sent for approval successfully", {
        className: "toast-success",
        theme: "colored",
      });
      fetchData();
    } catch (error) {
      return error;
    }
  };
  const handleDisableVendor = async (actorEmail, vendorId) => {
    try {
      await vendorService.disableVendor(actorEmail, vendorId);
      toast.success("Request sent for approval successfully", {
        className: "toast-success",
        theme: "colored",
      });
      fetchData();
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="vendor-list-container">
      <div style={{ width: "100%" }}>
        {vendorInfo?.map((data) => (
          <div
            key={data.id}
            style={{
              width: "100%",
              borderRadius: "20px",
              marginTop: "50px",
              margin: "0px auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "22px",
                width: "90%",
                margin: "0 auto",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "22px",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Card
                    sx={{
                      width: "320px",
                      margin: "0 auto",
                      height: "485.89px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "30px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "19px",
                        marginBottom: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          border: "10px solid #E6E6E6",
                          width: "129.5px",
                          height: "129.5px",
                          borderRadius: "80px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src="https://picsum.photos/200/300"
                          sx={{ width: 100, height: 100, marginTop: 2 }}
                        />
                      </div>

                      <p className="vendor-name">
                        {data.name}
                        <span>{data.description}</span>
                      </p>
                    </div>
                    <img src={data?.isActive ? active : inactive} alt="" />
                    <div
                      className="card1_bg"
                      style={{
                        background: data?.isActive ? "#255CFF" : "#aeaeae",
                      }}
                    >
                      <p className="vendor-info">
                        Settlement Acc: <span> {data.settlementAccount}</span>
                      </p>
                      <p className="vendor-info">
                        API Integration:
                        <span> {data?.apiIntegration ? "YES" : "NO"}</span>
                      </p>
                    </div>
                  </Card>
                </div>

                <div>
                  <Card
                    style={{
                      width: "550px",
                      height: "277px",
                      marginTop: "30px",
                      boxShadow: "none",
                    }}
                  >
                    <div className="card2_body">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="vendor-">
                          <img src={mail} alt="" />
                          <span
                            className="card2_subtext"
                            style={{ position: "relative", top: "-15px" }}
                          >
                            Email:
                          </span>
                        </p>
                        <p className="card2_subtext">{data.email}</p>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="vendor-">
                          <img src={phone} alt="" />
                          <span
                            className="card2_subtext"
                            style={{ position: "relative", top: "-15px" }}
                          >
                            Phone Contact:
                          </span>
                        </p>
                        <p className="card2_subtext"> {data.phone}</p>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="vendor-">
                          <img src={location} alt="" />
                          <span
                            className="card2_subtext"
                            style={{ position: "relative", top: "-15px" }}
                          >
                            City
                          </span>
                        </p>
                        <p className="card2_subtext"> {data.city}</p>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="vendor-">
                          <img src={location} alt="" />
                          <span
                            className="card2_subtext"
                            style={{ position: "relative", top: "-15px" }}
                          >
                            Country:
                          </span>
                        </p>
                        <p className="card2_subtext">{data.country}</p>
                      </div>
                    </div>
                  </Card>

                  <Card
                    style={{
                      maxWidth: "700px",
                      background: "#F5F5F5",
                      height: "170px",
                      marginTop: "38px",
                      borderRadius: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      columnGap: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <p className="card3_text">Comments about Vendor</p>
                    <p className="card3_subtext">{data.comment}</p>
                  </Card>
                </div>
              </div>
              <div
                style={{
                  margin: "30px 0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {data?.status === "PENDING" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                    }}
                  >
                    <ErrorOutlineIcon className="vendor-action-pending-text" />
                    <p className="vendor-action-pending-text">
                      There&apos;s a change currently pending for this vendor.
                    </p>
                  </div>
                ) : !data?.isActive ? (
                  <p className="vendor-action-inactive-text">
                    This Vendor is currently disabled.
                  </p>
                ) : (
                  ""
                )}

                {data.isActive ? (
                  <button
                    className="vendor-action-btn"
                    style={{ background: "#737373", marginLeft: "auto" }}
                    onClick={() => handleDisableVendor(actorEmail, data.id)}
                    disabled={data?.status === "PENDING"}
                  >
                    Disable
                  </button>
                ) : (
                  <button
                    className="vendor-action-btn"
                    style={{ marginLeft: "auto" }}
                    onClick={() => handleEnableVendor(actorEmail, data.id)}
                    disabled={data?.status === "PENDING"}
                  >
                    Enable
                  </button>
                )}
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VendorList;
