import React, { useState } from "react";
import Chart from "react-apexcharts";

const TransactionStatistics = ({ airtimeData }) => {
  const successfulTransaction = airtimeData?.map((item) => item.successful);
  const failedTransaction = airtimeData?.map((item) => item.failed);
  const transactionDate = airtimeData?.map((item) => item.date.substr(0, 10));

  const [option] = useState({
    title: { text: "Transaction Statistics" },
    xaxis: {
      type: "category",
      categories: transactionDate,
    },
    stroke: {
      curve: "smooth",
    },
  });

  const transactions = [
    {
      name: "Successful Transactions",
      data: successfulTransaction,
      color: "#255CFF",
    },

    {
      name: "Failed Transactions",
      data: failedTransaction,
      color: "#FF543D",
    },
  ];

  return (
    <div style={{ width: "91%", margin: "0 auto", fontFamily: "Open-Sans" }}>
      <Chart
        type="line"
        width="100%"
        height={550}
        series={transactions}
        options={option}
      ></Chart>
    </div>
  );
};

export default TransactionStatistics;
