// Library methods
import React, { useState, useEffect, useCallback } from "react";

// Components

//CSS
import "../../styles/ruleConfig.css";

// MUI Components
import { Card, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

// Utilities
import { settingsService } from "../../utils/api";
import pendingIcon from "../../assets/svg/rules/pending.svg";
import approvedIcon from "../../assets/svg/rules/approved.svg";
import rejectedIcon from "../../assets/svg/rules/rejected.svg";
import CenterModal from "../modal/center";
import SettingsModal from "./modals/SettingsModal";
import RejectionModal from "./modals/RejectionModal";
import { toast } from "react-toastify";

const SettingsApproval = () => {
  const [data, setData] = useState([]);
  const [rules, setRules] = useState([]);
  const [details, setDetails] = useState([]);
  const [requestPath, setRequestPath] = useState("PENDING");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);

  //get all approvals
  const fetchAllApprovals = useCallback(async () => {
    try {
      const response = await settingsService.getApprovalSettings();
      const sortedData = response?.data.sort((a, b) => b.id - a.id);
      setData(sortedData);
    } catch (error) {
      return error;
    }
  }, []);

  const handleRules = useCallback(
    (requestPath) => {
      const filteredRules = data?.filter(
        (item) => item?.approvalStatus === requestPath
      );
      setRules(filteredRules);
    },
    [data]
  );

  useEffect(() => {
    handleRules(requestPath);
  }, [handleRules, requestPath]);

  const pendingRules = data?.filter(
    (item) => item?.approvalStatus === "PENDING"
  );
  const approvedRules = data?.filter(
    (item) => item?.approvalStatus === "APPROVED"
  );
  const rejectedRules = data?.filter(
    (item) => item?.approvalStatus === "REJECTED"
  );

  const cards = [
    {
      img: pendingIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {pendingRules?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Awaiting Approval
          </Typography>
        </div>
      ),
      path: "PENDING",
    },

    {
      img: approvedIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {approvedRules?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Approved
          </Typography>
        </div>
      ),
      path: "APPROVED",
    },

    {
      img: rejectedIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {rejectedRules?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Rejected
          </Typography>
        </div>
      ),
      path: "REJECTED",
    },
  ];

  //approve a rule config
  const handleApprove = async (email, id) => {
    try {
      await settingsService.approveSettings(email, id).then((res) => {
        if (res?.status === 200) {
          toast.success("Changes approved successfully", {
            className: "toast-success",
            theme: "colored",
          });
          setIsSettingsOpen(false);
          window.location.reload();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  //reject a rule config
  const handleReject = async (email, id, reason) => {
    try {
      await settingsService.rejectSettings(email, id, reason).then((res) => {
        if (res?.status === 200) {
          toast.success("Changes rejected successfully", {
            className: "toast-success",
            theme: "colored",
          });
          setIsRejectOpen(false);
          handleRules(requestPath);
          window.location.reload();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const rows = rules?.map((item) => {
    return {
      initiatorEmail: item?.initiatorEmail,
      action: item?.action,
      date: item?.dateCreated?.substring(0, 10),
      approve: (
        <Button
          variant="contained"
          sx={{
            background: "#255cff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Open-Sans",
            "&:hover": {
              backgroundColor: "#255cff",
              color: "white",
              borderColor: "white",
            },
          }}
          onClick={() => {
            setDetails(item);
            setIsSettingsOpen(true);
          }}
        >
          View Details
        </Button>
      ),
    };
  });

  useEffect(() => {
    fetchAllApprovals();
  }, [fetchAllApprovals]);

  return (
    <Card
      sx={{
        background: "#f3f5ff",
        borderRadius: "5px",
        width: "98%",
        padding: "30px 0px",
        boxShadow: "none",
      }}
    >
      <Typography
        sx={{
          margin: "0px 30px",
          color: "#737373",
          fontSize: "20px",
          fontWeight: "600",
          fontFamily: "Open-Sans",
        }}
      >
        Settings Approval Queue
      </Typography>

      <Grid
        container
        height={"224px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 40px"}
        my={4}
        backgroundColor={"#f3f5ff"}
      >
        {cards?.map((item, idx) => (
          <Grid item key={idx}>
            <Card
              sx={{
                width: "210px",
                height: "80px 20px",
                padding: "30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                borderRadius: "23px",
                cursor: "pointer",
                background: "#FFFFFF",
                color:
                  requestPath === item?.path && requestPath === "PENDING"
                    ? "#FF9515"
                    : requestPath === item?.path && requestPath === "APPROVED"
                    ? "#1CC218"
                    : requestPath === item?.path && requestPath === "REJECTED"
                    ? "#CC0707"
                    : "#000",
                border:
                  requestPath === item?.path && requestPath === "PENDING"
                    ? "1px solid #FF9515"
                    : requestPath === item?.path && requestPath === "APPROVED"
                    ? "1px solid #1CC218"
                    : requestPath === item?.path && requestPath === "REJECTED"
                    ? "1px solid #CC0707"
                    : "none",
              }}
              onClick={() => {
                setRequestPath(item?.path);
              }}
            >
              <div
                style={{
                  background: "#EEF0F4",
                  width: "62px",
                  height: "62px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                }}
              >
                <img src={item?.img} alt="approval icon" />
              </div>
              <Typography m={2} fontSize={"16px"}>
                {item?.details}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        flexDirection={"column"}
        backgroundColor={"#fff"}
        minHeight={"100px"}
        padding={"30px 0"}
        borderRadius={"20px"}
      >
        {
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    S/N
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Initiator Email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {idx + 1}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row.initiatorEmail}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: "#1CC21826",
                          color: "#1CC218",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        {row?.action}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: "#EEF0F4",
                          color: "#737373",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        {row?.date}
                      </div>
                    </TableCell>
                    <TableCell>{row.approve}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>

      <CenterModal
        isOpen={isSettingsOpen}
        toggleModal={() => {
          setIsSettingsOpen(!isSettingsOpen);
        }}
      >
        <SettingsModal
          details={details}
          onConfirm={handleApprove}
          setIsRejectOpen={setIsRejectOpen}
          onCancel={setIsSettingsOpen}
        />
      </CenterModal>

      <CenterModal
        isOpen={isRejectOpen}
        toggleModal={() => {
          setIsRejectOpen(!isRejectOpen);
        }}
      >
        <RejectionModal
          details={details}
          onReject={handleReject}
          setIsRejectOpen={setIsRejectOpen}
        />
      </CenterModal>
    </Card>
  );
};

export default SettingsApproval;
