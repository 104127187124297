// Library methods
import React, { useState, useEffect, useCallback } from "react";

// Components

// MUI Components
import { Card, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

// Utilities
import { userService } from "../../utils/api";
import pendingIcon from "../../assets/svg/rules/pending.svg";
import approvedIcon from "../../assets/svg/rules/approved.svg";
import rejectedIcon from "../../assets/svg/rules/rejected.svg";
import CenterModal from "../modal/center";
import RejectionModal from "./modals/RejectionModal";
import { toast } from "react-toastify";

const NewUserApproval = () => {
  const [data, setData] = useState([]);
  const [rules, setRules] = useState([]);
  const [details, setDetails] = useState([]);

  const [requestPath, setRequestPath] = useState("PENDING");

  const [isRejectOpen, setIsRejectOpen] = useState(false);

  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  const fetchPendingUsers = async () => {
    try {
      const response = await userService.getNewUsersApproval();
      const sortedData = response?.data?.sort((a, b) => b.id - a.id);
      setData(sortedData);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  const handleRules = useCallback(
    (requestPath) => {
      const filteredRules = data?.filter(
        (item) => item?.approvalStatus === requestPath
      );
      setRules(filteredRules);
    },
    [data]
  );

  useEffect(() => {
    handleRules(requestPath);
  }, [handleRules, requestPath]);

  const pendingRules = data?.filter(
    (item) => item?.approvalStatus === "PENDING"
  );
  const approvedRules = data?.filter(
    (item) => item?.approvalStatus === "APPROVED"
  );
  const rejectedRules = data?.filter(
    (item) => item?.approvalStatus === "REJECTED"
  );

  const cards = [
    {
      img: pendingIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {pendingRules?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Awaiting Approval
          </Typography>
        </div>
      ),
      path: "PENDING",
    },

    {
      img: approvedIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {approvedRules?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Approved
          </Typography>
        </div>
      ),
      path: "APPROVED",
    },

    {
      img: rejectedIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {rejectedRules?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Rejected
          </Typography>
        </div>
      ),
      path: "REJECTED",
    },
  ];

  //approve a user config
  const handleApprove = async (email, id) => {
    try {
      await userService.approveUser(email, id).then((res) => {
        if (res?.status === 200) {
          toast.success("User approved successfully", {
            className: "toast-success",
            theme: "colored",
          });
          window.location.reload();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  //reject a user config
  const handleReject = async (email, id, reason) => {
    try {
      await userService.rejectUser(email, id, reason).then((res) => {
        if (res?.status === 200) {
          toast.success("User rejected successfully", {
            className: "toast-success",
            theme: "colored",
          });
          setIsRejectOpen(false);
          window.location.reload();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const rows = rules?.map((item) => {
    return {
      username: item?.username,
      name: `${item?.firstName} ${item?.lastName}`,
      role: item?.userType,
      email: item?.email,
      date: `${item?.createdAt?.substring(
        0,
        10
      )} - ${item?.createdAt?.substring(11, 16)}`,
      action: (
        <div
          style={{
            display: item?.approvalStatus === "PENDING" ? "flex" : "none",
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#255cff",
              textTransform: "capitalize",
              marginRight: "10px",
              boxShadow: "none",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255cff",
                color: "white",
                borderColor: "white",
              },
            }}
            onClick={() => handleApprove(actorEmail, item?.id)}
          >
            Approve
          </Button>
          <Button
            variant=""
            sx={{
              background: "#fffff",
              textTransform: "capitalize",
              borderColor: "#255cff",
              borderWidth: "1px",
              borderStyle: "solid",
              boxSizing: "border-box",
              boxShadow: "none",
              fontFamily: "Open-Sans",
            }}
            onClick={() => {
              setDetails(item);
              setIsRejectOpen(true);
            }}
          >
            Reject
          </Button>
        </div>
      ),
    };
  });

  return (
    <Card
      sx={{
        background: "#f3f5ff",
        borderRadius: "5px",
        width: "98%",
        padding: "30px 0px",
        boxShadow: "none",
      }}
    >
      <Typography
        sx={{
          margin: "0px 30px",
          color: "#737373",
          fontSize: "20px",
          fontWeight: "600",
          fontFamily: "Open-Sans",
        }}
      >
        User Approval Queue
      </Typography>

      <Grid
        container
        height={"224px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 40px"}
        my={4}
        backgroundColor={"#f3f5ff"}
      >
        {cards?.map((item, idx) => (
          <Grid item key={idx}>
            <Card
              sx={{
                width: "210px",
                height: "80px",
                padding: "30px 20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                borderRadius: "23px",
                cursor: "pointer",
                background: "#FFFFFF",
                color:
                  requestPath === item?.path && requestPath === "PENDING"
                    ? "#FF9515"
                    : requestPath === item?.path && requestPath === "APPROVED"
                    ? "#1CC218"
                    : requestPath === item?.path && requestPath === "REJECTED"
                    ? "#CC0707"
                    : "#000",
                border:
                  requestPath === item?.path && requestPath === "PENDING"
                    ? "1px solid #FF9515"
                    : requestPath === item?.path && requestPath === "APPROVED"
                    ? "1px solid #1CC218"
                    : requestPath === item?.path && requestPath === "REJECTED"
                    ? "1px solid #CC0707"
                    : "none",
              }}
              onClick={() => {
                setRequestPath(item?.path);
              }}
            >
              <div
                style={{
                  background: "#EEF0F4",
                  width: "62px",
                  height: "62px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                }}
              >
                <img src={item?.img} alt="approval icon" />
              </div>
              <Typography m={2} fontSize={"16px"}>
                {item?.details}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        flexDirection={"column"}
        backgroundColor={"#fff"}
        minHeight={"100px"}
        padding={"30px 0"}
        borderRadius={"20px"}
      >
        {
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Username
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Date Added
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#737373" }}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row.role}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: "#EEF0F4",
                          color: "#737373",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        {row?.date}
                      </div>
                    </TableCell>
                    <TableCell>{row.action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>

      <CenterModal
        isOpen={isRejectOpen}
        toggleModal={() => {
          setIsRejectOpen(!isRejectOpen);
        }}
      >
        <RejectionModal
          details={details}
          onReject={handleReject}
          setIsRejectOpen={setIsRejectOpen}
        />
      </CenterModal>
    </Card>
  );
};

export default NewUserApproval;
