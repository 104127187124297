import * as React from "react";

import TimerIcon from "@mui/icons-material/Timer";
import Box from "@mui/material/Box";
import "../../styles/ruleConfig.css";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RulesApproval from "../../components/approval/RulesApproval";
import PriorityApproval from "../../components/approval/PriorityApproval";
import VendorApproval from "../../components/approval/VendorApproval";
import SettingsApproval from "../../components/approval/SettingsApproval";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Approval = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dashboard-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "15px",
          }}
        >
          <div>
            <h2 className="dash-header">Approve Routing Rule</h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss a")}</p>
            </div>
          </div>
        </div>

        <div>
          <p className="ruleConfig-header">
            Configure routing rules to automatically allow system detect
            customer actions by enabling the set configured rules
          </p>
        </div>

        <div className="ruleConfig-tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: { backgroundColor: "#255cff", height: 4 },
            }}
            sx={{
              width: "90%",
              margin: "0 auto",
              height: "72px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              "& button:active": { color: "#255cff" },
              "& button.Mui-selected": { color: "#255cff" },
            }}
          >
            <Tab
              label="Rule Approvals"
              {...a11yProps(0)}
              sx={{
                fontSize: "17px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />
            <Tab
              label="Priority Approval"
              {...a11yProps(1)}
              sx={{
                fontSize: "17px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />
            <Tab
              label="Vendor"
              {...a11yProps(2)}
              sx={{
                fontSize: "17px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />

            <Tab
              label="Settings Approval"
              {...a11yProps(3)}
              sx={{
                fontSize: "17px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />
          </Tabs>
        </div>

        <Box sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <RulesApproval />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PriorityApproval />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <VendorApproval />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <SettingsApproval />
          </TabPanel>
        </Box>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
    </div>
  );
};

export default Approval;
