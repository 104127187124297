// Library methods
import React, { useState, useEffect } from "react";

// Components
import TimeCheckbok from "./TimeCheckbox";
import BankCheckbox from "./BankCheckbox";
import NetworkCheckbox from "./NetworkCheckbox";

// MUI Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Utilities
import { rulesService, vendorService } from "../../../utils/api";

const RuleConfigSection = () => {
  const [ruleTypes, setRuleTypes] = useState([]);
  const [active, setActive] = useState(0);
  const [vendors, setVendors] = useState([]);

  const fetchRuleTypes = async () => {
    try {
      const response = await rulesService.getRuleTypes();
      // console.log(response.data)
      setRuleTypes(response?.data);
    } catch (error) {
      return error;
      throw error;
    }
  };

  const fetchVendors = async () => {
    try {
      const response = await vendorService.getAllVendors();
      setVendors(response?.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchRuleTypes();
    fetchVendors();
  }, []);

  return (
    <Card
      sx={{
        borderRadius: "20px",
        width: "94%",
        padding: "30px",
        boxShadow: "none",
      }}
    >
      <Typography
        sx={{
          margin: "0px 30px",
          color: "#737373",
          fontSize: "20px",
          fontWeight: "600",
          fontFamily: "Open-Sans",
        }}
      >
        Rules
      </Typography>

      <Grid container flexDirection={"column"}>
        {ruleTypes?.map((item, idx) => (
          <Accordion
            key={idx}
            xs={12}
            sx={{
              my: 2,
              py: 4,
              px: 2,
              boxShadow: "none",
              borderTop:
                active === item?.id ? "1px solid #255cff" : "1px solid #BBBBBB",
              borderBottom:
                active === item?.id ? "1px solid #255cff" : "1px solid #BBBBBB",
              backgroundColor: active === item?.id ? "#ffffff" : "#EEF0F4",
            }}
            onChange={() => setActive(item?.id)}
            // display={"flex"}
            // alignItems={"center"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontFamily: "Open-Sans" }}>
                {item?.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item?.name === "Network" ? (
                <NetworkCheckbox vendors={vendors} ruleTypeId={item?.id} />
              ) : item?.name === "TimeOfDay" ? (
                <TimeCheckbok vendors={vendors} ruleTypeId={item?.id} />
              ) : item?.name === "Channel" ? (
                <BankCheckbox vendors={vendors} ruleTypeId={item?.id} />
              ) : (
                ""
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Card>
  );
};

export default RuleConfigSection;
