// Library methods
import React, { useState, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";

// Components
import CenterModal from "../../components/modal/center";
import SettlementDetail from "../../components/settlement/NetworkSettlementDetail";

// MUI Components
import { Box, Card, Grid, TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

// Utilities
import { settlementService } from "../../utils/api";
import exportIcon from "../../assets/svg/dashboard/exportIcon.svg";
import { formatPickerDate } from "../../utils/helpers";

const NetworkSettlement = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [details, setDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const fetchSettlements = useCallback(async () => {
    try {
      const response = await settlementService.getAllSettlements();
      const filteredData = response?.data
        ?.filter((item) => item?.settlementStatus === "PENDING")
        ?.sort((a, b) => b.Id - a.Id);
      setData(filteredData);
    } catch (error) {
      return error;
    }
  }, []);

  const rows = data?.map((item, idx) => {
    return {
      name: item?.VendorName,
      ruleType: item?.NetworkName,
      date: `${item?.createdAt?.substring(
        0,
        10
      )} - ${item?.createdAt?.substring(11, 16)}`,
      action: (
        <Button
          variant="contained"
          sx={{
            background: "#255cff",
            fontFamily: "Open-Sans",
            textTransform: "capitalize",
            boxShadow: "none",
          }}
          onClick={() => {
            setDetails(item);
            setIsOpen(true);
          }}
        >
          View Details
        </Button>
      ),
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchSettlements();
  }, [fetchSettlements]);

  return (
    <Card
      sx={{
        borderRadius: "20px",
        width: "94%",
        padding: "30px",
        boxShadow: "none",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} my={2}>
        <Typography
          sx={{
            margin: "0px 30px",
            color: "#737373",
            fontSize: "20px",
            fontWeight: "600",
            fontFamily: "Open-Sans",
          }}
        >
          Settlement Required
        </Typography>

        {data?.length > 0 && (
          <div className="transaction-header-div-share">
            <CSVLink
              data={data}
              filename={`settlement-initiation-data_${formatPickerDate(
                new Date()
              )}.csv`}
            >
              <img src={exportIcon} alt="download" />
            </CSVLink>
          </div>
        )}
      </Box>

      <Grid container flexDirection={"column"}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Vendor Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Network Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TableRow key={row?.name}>
                    <TableCell sx={{ fontFamily: "Open-Sans" }}>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          background:
                            row?.ruleType === "MTN"
                              ? "#FFD953"
                              : row?.ruleType === "GLO"
                              ? "#72F651"
                              : row?.ruleType === "AIRTEL"
                              ? "#FF543D"
                              : "#3C8702",
                          color:
                            row?.ruleType === "MTN" || row?.ruleType === "GLO"
                              ? "#000"
                              : "#fff",
                          maxWidth: "90px",
                          height: "30px",
                          fontFamily: "Open-Sans",
                          padding: "0px 5px",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {row?.ruleType}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: "#EEF0F4",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          fontFamily: "Open-Sans",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {row?.date}
                      </div>
                    </TableCell>
                    <TableCell>{row?.action}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>

      <CenterModal
        isOpen={isOpen}
        toggleModal={() => {
          setIsOpen(!isOpen);
        }}
      >
        <SettlementDetail
          details={details}
          onCancel={setIsOpen}
          type={"initiate"}
        />
      </CenterModal>
    </Card>
  );
};

export default NetworkSettlement;
