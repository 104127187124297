// Library methods
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  // useAsyncDebounce,
} from "react-table";
//icons
import TimerIcon from "@mui/icons-material/Timer";
import exportIcon from "../assets/svg/dashboard/exportIcon.svg";

// Components
import CenterModal from "../components/modal/center";
import ChannelActivityModal from "../components/modal/activityLog/ChannelActivityModal";
import NetworkActivityModal from "../components/modal/activityLog/NetworkActivityModal";
import SettingsActivityModal from "../components/modal/activityLog/SettingsActivityModal";
// MUI Components
import { Card, Typography } from "@mui/material";

// Utilities
import { auditService } from "../utils/api";
import "../styles/users.css";
import "../styles/activity.css";
import "../styles/ruleConfig.css";

const ActivityLog = () => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const [channelModalOpen, setChannelModalOpen] = useState(false);
  const [networkModalOpen, setNetworkModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const downloadData = data?.map((item) => {
    const beforeData = JSON.parse(item?.before);
    const afterData = JSON.parse(item?.after);

    const convertObj = (obj) => {
      const keys = Object.keys(obj);
      const header = keys.join(", ") + "\n";
      const values = keys.map((key) => {
        const value = obj[key];
        // if (/[",\n]/.test(value)) {
        //   return `"${value.replace(/"/g, '""')}"`;
        // } else {
        //   return value;
        // }
        return value;
      });
      return header + values.join(", ") + "\n";
    };

    return {
      Email: item?.email,
      Action: item?.action,
      Before: item?.before !== null ? convertObj(beforeData) : "-",
      After: item?.after !== null ? convertObj(afterData) : "-",
      Date: item?.eventTime?.substr(0, 10),
      Time: item?.eventTime?.substr(11, 17),
    };
  });

  const fetchLogs = useCallback(async () => {
    try {
      const response = await auditService.getUserActivity();

      const sortedData = response.data?.sort((a, b) => b.id - a.id);
      setData(sortedData);
    } catch (error) {
      return error;
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "index",
        Header: "ID",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        id: "action",
        Header: "Action",
        accessor: (data) => (
          <div
            style={{
              background:
                data?.action === "LOGIN"
                  ? "#1CC21826"
                  : data?.action === "LOGOUT"
                  ? "#CC070726"
                  : null,
              color:
                data?.action === "LOGIN"
                  ? "#1CC218"
                  : data?.action === "LOGOUT"
                  ? "#CC0707"
                  : "#737373",
              maxWidth: "140px",
              height: "25px",
              margin: "0 auto",
              padding: "0px 5px",
              borderRadius: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.action}
          </div>
        ),
      },

      {
        id: "eventTime",
        Header: "Date",
        accessor: (data) => (
          <div
            style={{
              backgroundColor: "#EEF0F4",
              borderRadius: "30px",
              maxWidth: "160px",
              height: "25px",
              margin: "0 auto",
              padding: "0px 5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >{`${data?.eventTime.substr(0, 10)} - ${data?.eventTime.substring(
            11,
            19
          )}`}</div>
        ),
      },
      {
        id: "before",
        Header: "View Details",
        accessor: (data) =>
          data?.before !== null && data?.after !== null ? (
            <button
              onClick={() => {
                setDetails(data);
                if (data?.action === "MODIFY_NETWORK_RULE") {
                  setNetworkModalOpen(true);
                } else if (data?.action === "MODIFY_CHANNEL_RULE") {
                  setChannelModalOpen(true);
                } else if (data?.action === "MODIFY_SETTINGS") {
                  setSettingsModalOpen(true);
                } else {
                  return null;
                }
              }}
              className="table-details-btn"
            >
              View Details
            </button>
          ) : (
            "-"
          ),
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    // setGlobalFilter,
    state: {
      pageSize,
      pageIndex, // globalFilter
    },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  return (
    <div className="dashboard-container">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h2 className="dash-header">Activity log</h2>

            <div style={{ display: "flex" }}>
              <div className="time">
                <TimerIcon />
                <p>Time: {moment().format("h:mm:ss a")}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="ruleConfig-header">
            Here you can see all recent activities that occurred, easily track
            your recent activities on this page.
          </p>
        </div>
        <Card
          sx={{
            borderRadius: "20px",
            width: "92%",
            padding: "10px 30px 100px 30px",
            boxShadow: "none",
          }}
        >
          <div
            style={{
              margin: "43px 5px 20px 5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#255CFF",
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            >
              All in app activities
            </Typography>
            {data?.length > 0 && (
              <div
                className="transaction-header-div-share"
                style={{ marginLeft: "auto", marginBottom: "10px" }}
              >
                <CSVLink data={downloadData} filename={`activity-log.csv`}>
                  <img src={exportIcon} alt="download" />
                </CSVLink>
              </div>
            )}
          </div>
          <div style={{ width: "100%", border: "1px" }}>
            <table
              {...getTableProps()}
              style={{ width: "100%", border: "1px" }}
            >
              <thead>
                {headerGroups?.map((headerGroup) => (
                  <tr
                    className="table-header"
                    {...headerGroup?.getHeaderGroupProps()}
                  >
                    {headerGroup?.headers?.map((column) => (
                      <th
                        style={{ fontFamily: "Open-Sans", fontWeight: "800" }}
                        colSpan={visibleColumns?.length}
                        {...column?.getHeaderProps()}
                      >
                        {column?.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page?.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      className="table-content "
                      style={{ background: "#ffffff" }}
                      {...row?.getRowProps()}
                    >
                      {row?.cells?.map((cell) => (
                        <td {...cell?.getCellProps()}>
                          {cell?.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="table-pagination-cont">
              <div className="table-pagination-footer-cont">
                <p
                  style={{
                    color: "#737373",
                    paddingRight: "10px",
                    fontSize: "14px",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Show
                </p>
                <select
                  name=""
                  id=""
                  className="table-pagination-dropdown"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                <p
                  style={{
                    color: "#737373",
                    paddingRight: "10px",
                    fontSize: "14px",
                    fontFamily: "Open-Sans",
                  }}
                >
                  entries
                </p>
              </div>
              <div className="table-pagination-footer-cont">
                <p className="table-pagination-size">
                  Page &nbsp;{pageIndex + 1} of {pageOptions.length}
                </p>
                <div className="table-pagination-btn-cont">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className={`${
                      !canPreviousPage
                        ? "table-pagination-btn-disabled "
                        : "table-pagination-btn"
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className={`${
                      !canNextPage
                        ? "table-pagination-btn-disabled "
                        : "table-pagination-btn"
                    }`}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <CenterModal
          isOpen={networkModalOpen}
          toggleModal={() => {
            setNetworkModalOpen(!networkModalOpen);
          }}
        >
          <NetworkActivityModal
            details={details}
            onCancel={setNetworkModalOpen}
          />
        </CenterModal>
        <CenterModal
          isOpen={channelModalOpen}
          toggleModal={() => {
            setChannelModalOpen(!channelModalOpen);
          }}
        >
          <ChannelActivityModal
            details={details}
            onCancel={setChannelModalOpen}
          />
        </CenterModal>

        <CenterModal
          isOpen={settingsModalOpen}
          toggleModal={() => {
            setSettingsModalOpen(!settingsModalOpen);
          }}
        >
          <SettingsActivityModal
            details={details}
            onCancel={setSettingsModalOpen}
          />
        </CenterModal>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
    </div>
  );
};

export default ActivityLog;
