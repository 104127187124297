import React, {useState} from 'react'
import Chart from 'react-apexcharts';



const ApexChart = () => {
    const [transactions] = useState([

        {
            name: 'Successful Transactions',
            data: [0, 5, 20, 40, 20, 40 , 45, 50 ],
            color: '#00D9C2'
        }, 

        {
            name: 'Failed Transactions',
            data: [10, 5, 10 , 25, 20, 25, 10, 20],
            color: '#FF543D'
        }
    ])

    const [option] = useState(
        {
     
            title:{text: 'Traffic Statistics' }
        },

    )

        return (
            <div style={{width: '91%', margin: '0 auto'}}> 
            <Chart type='line'
            width='100%'
            height={550}
            series={transactions}
            options={option}>
                
            </Chart>
            </div>
  )
}

export default ApexChart
