import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

const DetailModal = ({ details, onConfirm, setIsRejectOpen, onCancel }) => {
  const currentData = JSON?.parse(details?.current);
  const proposedData = JSON?.parse(details?.proposed);

  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;
  return (
    <Grid
      container
      sx={{
        width: "700px",
        maxHeight: "100vh",
        padding: "30px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "scroll",
      }}
      className="scrollbar"
    >
      <Grid
        item
        width={"80%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#000000",
            fontFamily: "Open-Sans",
          }}
        >
          NETWORK RULE APPROVAL
        </Typography>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tbody
            style={{
              margin: "0px auto",
            }}
          >
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-header-data">
                <p>Current</p>
              </td>
              <td className="rule-approval-table-header-data">
                <p>Proposed</p>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5> <p>{currentData?.Email ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Modifier</h5>
                  <p>{proposedData?.Email ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Vendor</h5> <p>{currentData?.Name ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Vendor</h5>
                  <p>{proposedData?.Name ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN</h5>{" "}
                  <p>{currentData?.Mtn ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN</h5>
                  <p>{proposedData?.Mtn ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN Rate</h5>{" "}
                  <p>{currentData?.MtnRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-mtn">MTN Rate</h5>
                  <p>{proposedData?.MtnRate ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL</h5>
                  <p>{currentData?.Airtel ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL</h5>
                  <p>{proposedData?.Airtel ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL Rate</h5>{" "}
                  <p>{currentData?.AirtelRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-airtel">AIRTEL Rate</h5>
                  <p>{proposedData?.AirtelRate ?? "-"}</p>
                </div>
              </td>
            </tr>

            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO</h5>{" "}
                  <p>{currentData?.Glo ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO</h5>
                  <p>{proposedData?.Glo ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO Rate</h5>{" "}
                  <p>{currentData?.GloRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-glo">GLO Rate</h5>
                  <p>{proposedData?.GloRate ?? "-"}</p>
                </div>
              </td>
            </tr>

            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE</h5>{" "}
                  <p>{currentData?.NineMobile ? "True" : "False"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE</h5>
                  <p>{proposedData?.NineMobile ? "True" : "False"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE Rate</h5>
                  <p>{currentData?.NineMobileRate ?? "-"}</p>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5 className="rule-approval-9mobile">9MOBILE Rate</h5>
                  <p>{proposedData?.NineMobileRate ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont"></div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Date Created</h5>
                  <p className="rule-approval-date">
                    {details?.createdAt
                      ? `${details?.createdAt?.substring(0, 10)} - 
                    ${details?.createdAt?.substring(11, 16)}`
                      : "-"}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Box
          paddingTop={"40px"}
          display={details?.approvalStatus === "PENDING" ? "flex" : "none"}
          justifyContent={"space-around"}
          columnGap={"20px"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#fff",
              textTransform: "capitalize",
              color: "#255cff",
              fontFamily: "Open-Sans",
              border: "2px solid #255cff",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#B3B3B3",
              },
            }}
            onClick={() => {
              setIsRejectOpen(true);
              onCancel(false);
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#255cff",
              textTransform: "capitalize",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255cff",
              },
            }}
            onClick={() => {
              onConfirm(actorEmail, details?.id);
              onCancel(false);
            }}
          >
            Approve
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DetailModal;
