import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, Typography } from "@mui/material";

import { rulesService } from "../../utils/api";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const BankChannel = () => {
  const [rulesHistory, setRulesHistory] = useState([]);
  const [loadCount, setLoadCount] = useState(10);
  const [cardHeight, setCardHeight] = useState({});
  const [isCardOpen, setIsCardOpen] = useState({});

  const fetchChannelRules = async () => {
    try {
      const response = await rulesService.getChannelRules();
      setRulesHistory(response?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchChannelRules();
  }, []);
  const ruleHistory = rulesHistory?.map((data) => {
    return data?.ruleHistory;
  });
  const allHistory = ruleHistory
    ?.reduce((acc, curr) => acc.concat(curr), [])
    ?.sort((a, b) => b.id - a.id)
    ?.slice(0, loadCount);

  const updateCardState = (itemId, height, bol) => {
    setCardHeight((prevHeight) => ({ ...prevHeight, [itemId]: height }));
    setIsCardOpen((prevVal) => ({ ...prevVal, [itemId]: bol }));
  };

  return (
    <Card
      sx={{
        width: "90%",
        padding: "35px 60px 35px 24px",
        boxShadow: "none",
        borderRadius: "20px",
      }}
    >
      <NavLink to="/admin/configuration">
        <KeyboardBackspaceIcon sx={{ marginBottom: "40px" }} />
      </NavLink>

      <div>
        {allHistory?.map((item) => (
          <Card
            key={item.id}
            sx={{
              height: cardHeight[item.id] || "201px",
              marginBottom: "45px",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
                borderLeft: "1px dashed #D9D9D9",
              }}
            >
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isCardOpen[item.id] ? (
                  <ArrowDropDownIcon
                    sx={{
                      color: "#255cff",
                      mr: "16px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      updateCardState(item.id, "201px", false);
                    }}
                  />
                ) : (
                  <ArrowRightIcon
                    sx={{
                      color: "#255cff",
                      mr: "14px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      updateCardState(item.id, "485px", true);
                    }}
                  />
                )}

                <Typography
                  sx={{
                    color: "#000000",
                    margin: "0px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Changes made on {item?.data?.name}, on{" "}
                  {item.createdAt?.substr(0, 10)}, by{" "}
                  <span style={{ color: "#255cff" }}>{item?.createdBy}</span>
                </Typography>
              </div>
              <table
                style={{
                  width: "100%",
                  marginLeft: "45px",
                  borderCollapse: "collapse",
                }}
              >
                <tbody
                  style={{
                    margin: "0px auto",
                  }}
                >
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>ID</h5> <p>{item?.id}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Status</h5>
                        <p
                          style={{
                            color:
                              item.status === "PENDING"
                                ? "#FF9515"
                                : item.status === "APPROVED"
                                ? "#04CA00"
                                : "#FF5B5B",
                          }}
                        >
                          {item?.status}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Rule Name</h5> <p>{item?.data?.name}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Vendor ID</h5> <p>{item?.data?.vendorId}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>ATM</h5>
                        <p
                          style={{
                            color: item?.data?.atm ? "#04CA00" : "#FF5B5B",
                          }}
                        >
                          {item?.data.atm?.toString()}
                        </p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>INTERNET</h5>
                        <p
                          style={{
                            color: item?.data?.internet ? "#04CA00" : "#FF5B5B",
                          }}
                        >
                          {item?.data?.internet?.toString()}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>MOBILE</h5>
                        <p
                          style={{
                            color: item?.data?.mobile ? "#04CA00" : "#FF5B5B",
                          }}
                        >
                          {item?.data.mobile?.toString()}
                        </p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>USSD</h5>
                        <p
                          style={{
                            color: item?.data?.ussd ? "#04CA00" : "#FF5B5B",
                          }}
                        >
                          {item?.data.ussd?.toString()}
                        </p>
                      </div>
                    </td>
                  </tr>

                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Created By</h5> <p>{item?.createdBy}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Created At</h5>
                        <p>{item?.createdAt?.substr(0, 10)}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="history-table-row">
                    <td className="history-table-data">
                      <div className="history-table-data-cont">
                        <h5>Approved At</h5>{" "}
                        <p>{item?.approvedAt?.substr(0, 10)}</p>
                      </div>
                    </td>
                    <td className="history-table-data">
                      <div className="history-table-data-cont"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        ))}
      </div>
      <button
        onClick={() => setLoadCount((prev) => prev + 10)}
        disabled={loadCount > allHistory?.length}
        className="rule-history-button"
        style={{ display: allHistory?.length < 10 && "none" }}
      >
        <AccessTimeIcon sx={{ marginRight: "6px" }} /> Load Older Changes
      </button>
    </Card>
  );
};

export default BankChannel;
