import { authService } from "./api";

export const getToken = () => {
  let token = "";
  try {
    token = sessionStorage.getItem("token");
  } catch (err) {
    //err
  }
  return token;
};
export const refreshToken = async () => {
  let user = "";
  let token = "";
  let refreshToken = "";
  try {
    user = JSON.parse(sessionStorage.getItem("user"));
    token = JSON.parse(sessionStorage.getItem("user")).jwToken;
    refreshToken = JSON.parse(sessionStorage.getItem("user")).jwToken;
    let payload = {
      refresh_token: refreshToken,
      access_token: token,
    };

    const data = await authService.refresh(payload);

    if (data) {
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          jwToken: data?.access_token,
          refreshToken: data?.refresh_token,
        })
      );
    }
  } catch (err) {
    //err
  }
  return token;
};

export const formatPickerDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
