import React from "react";
import SideBar from "./SideBar";

import Mainbar from "../../../components/Mainbar";
const Main = () => {
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <Mainbar />
      </div>
    </div>
  );
};

export default Main;
