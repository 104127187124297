import { Grid } from "@mui/material";
import React from "react";
import Portal from "../portal";

const CenterModal = ({
  toggleModal,
  isOpen,
  children,
  borderRadius,
  bgcolor,
  width,
  height,
}) => {
  return (
    <Portal>
      <Grid
        container
        backgroundColor="rgba(52, 64, 84, 0.7)"
        justifyContent="center"
        alignItems="center"
        onClick={toggleModal}
        position="fixed"
        top={"0"}
        bottom={"0"}
        right={"0"}
        left={"0"}
        style={{
          visibility: isOpen ? "visible" : "hidden",
          transitionDelay: isOpen ? "0s" : "0.2s",
          cursor: "pointer",
          zIndex: 100,
        }}
      >
        <Grid
          item
          transform={isOpen ? "scale(1)" : "scale(0)"}
          position="relative"
          backgroundColor={bgcolor || "#FFFFFF"}
          borderRadius={borderRadius}
          width={width}
          height={height}
          onClick={(e) => e.stopPropagation()}
          style={{
            transition: "all 0.2s ease-in-out",
            cursor: "auto",
          }}
        >
          {isOpen && children}
        </Grid>
      </Grid>
    </Portal>
  );
};

export default CenterModal;

// const Background = styled.div`
//   background-color: rgba(52, 64, 84, 0.7);
//   backdrop-filter: blur(8px);
//   width: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   z-index: 100;
//   display: flex;
//   justify-content: ${({ $justify }) => $justify ?? "center"};
//   align-items: center;
//   cursor: pointer;
//   overflow: auto;
//   padding: 2rem 0;
// `;

// const MainContainer = styled.div`
//   overflow: auto;

//   cursor: auto;
//   background-color: ${({ theme, $bgcolor }) => $bgcolor ?? theme.color.white};
//   border-radius: ${({ $borderRadius }) => $borderRadius ?? "0"}px;
//   width: ${({ $width }) => $width ?? "auto"};
//   height: ${({ $height }) => $height ?? "auto"};
//   position: relative;
//   animation: scaleUp 0.1s ease-in-out;
//   transform: scale(1);

//   @keyframes scaleUp {
//     0% {
//       transform: scale(0);
//     }
//     100% {
//       transform: scale(1);
//     }
//   }
// `;
