import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const NetworkSettlementDetail = ({ details, onCancel, type }) => {
  const mappedArray = Object?.entries(details)?.map(([key, value]) => {
    return { key: key, value: value };
  });

  return (
    <Grid container py={1} px={6} width={"500px"}>
      <Grid item width={"100%"}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "36px",
            color: "#255cff",
            textTransform: "uppercase",
          }}
        >
          {`${type} SETTLEMENT`}
        </Typography>

        {mappedArray?.map((item) => (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{ fontSize: "16px", lineHeight: "24px" }}>
              {item?.key}:
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "24px" }}>
              {item?.value === null
                ? "-"
                : item?.value === true
                ? "true"
                : item?.value === false
                ? "false"
                : item?.value}
            </Typography>
          </Box>
        ))}

        <Box py={2} display={"flex"} justifyContent={"space-around"}>
          <Button
            variant="contained"
            sx={{
              background: "#fff",
              textTransform: "capitalize",
              color: "#255cff",
              border: "2px solid #255cff",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#B3B3B3",
              },
            }}
            onClick={() => {
              onCancel(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NetworkSettlementDetail;
