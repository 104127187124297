import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const user = sessionStorage.getItem('user');
    return user;
    // return true;
};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? (
    <Outlet /> ): <Navigate to="auth/login" />;
};

export default ProtectedRoutes;