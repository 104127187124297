import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken, //refreshToken
} from "./helpers";

const BASEURL =
  process.env.REACT_APP_API_ENDPOINT ||
  "https://intelligent-stage-54dba42e73b2.herokuapp.com/v1";

let APIKit = axios.create({
  baseURL: BASEURL,
  timeout: 10000, // timeout can be increased
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// APIKit.interceptors.request.use(async (config) => {
//   const response = await axios.get("https://api.ipify.org?format=json");
//   config.headers["clientIpAddress"] = response.data.ip;
//   return config;
// });

APIKit.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response ? error.response.status : null;

  if (error?.response?.data?.message === "Internal Server Error") {
    toast.error(
      "Something went wrong. Please check that you inputted the correct details and try again"
    );
  } else if (error?.config?.responseType === "blob"){
    error.response?.data?.text()?.then(message=>{  //blob to text
      try {
        // text back to json e.g {error: [message]}
        toast.error(JSON.parse(message)?.error);        
      } catch (error) {
        toast.error(message);        
      }
    }); 
  } else if (error?.response?.data?.error) {
    toast.error(error.response.data.error);
  } else if (error?.response?.data?.errors) {
    toast.error(error.response.data.errors[0]);
  } else {
    toast.error(error?.response?.data?.message);
  }

  if (statusCode && statusCode === 401) {
    toast.error("session expired, redirecting to login...");
    setTimeout(() => {
      window.location = "/auth/login";
    }, 1000);
  }

  if (statusCode && statusCode === 403) {
    window.location = "/admin/error";
  }
});

//auth
export const authService = {
  login: (payload) => {
    return APIKit.post("/login", payload);
  },

  authentication: (payload) => {
    return APIKit.post("/account/secondFactorauth", payload);
  },
  activate: (payload) => {
    return APIKit.post("/account/activate", payload);
  },

  refresh: (payload) => {
    return APIKit.post("/account/refresh-token", payload);
  },
};

export const wakeService = {
  wakeCall: () => {
    return APIKit.get("/dashboard/wake");
  },
};

export const encryptService = {
  encrypt: (payload) => {
    return APIKit.post(`/utility/encrypt`, payload);
  },
};

export const dashboardService = {
  getCardData: (date) => {
    return APIKit.get(`/summary?date=${date}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};

// rules
export const rulesService = {
  getRules: () => {
    return APIKit.get("/rules", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  createRule: (payload) => {
    return APIKit.post(`/rules`, payload);
  },

  updateRule: (id, payload) => {
    return APIKit.patch(`/rules/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  //network rules
  getNetworkRules: () => {
    return APIKit.get("/rules/network", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  createNetworkRule: (payload) => {
    return APIKit.post(`/rules/network`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  updateNetworkRule: (id, payload) => {
    return APIKit.patch(`/rules/network/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  //channel rules
  getChannelRules: () => {
    return APIKit.get("/rules/channel", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  createChannelRule: (payload) => {
    return APIKit.post(`/rules/channel`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  updateChannelRule: (id, payload) => {
    return APIKit.patch(`/rules/channel/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  //timeOfDay rules
  getTimeOfDayRules: () => {
    return APIKit.get("/rules/timeofday", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  createTimeOfDayRule: (payload) => {
    return APIKit.post(`/rules/timeofday`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  updateTimeOfDayRule: (id, payload) => {
    return APIKit.patch(`/rules/timeofday/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  //rule types
  getRuleTypes: () => {
    return APIKit.get("/priority", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getRulesById: (id) => {
    return APIKit.get(`/ruletypes/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};

// settlement
export const settlementService = {
  getAllSettlements: () => {
    return APIKit.get(`/settlement`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getSettlementById: (id) => {
    return APIKit.get(`/settlement/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  getVendorSettlements: () => {
    return APIKit.get(`/settlement/vendor`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getVendorSettlementById: (id) => {
    return APIKit.get(`/settlement/vendor/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  initiateSettlement: (actorEmail, id) => {
    return APIKit.post(
      `/settlement/initiate?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  archiveSettlement: (actorEmail, id) => {
    return APIKit.post(
      `/settlement/archive?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  approveSettlement: (actorEmail, id) => {
    return APIKit.post(
      `/settlement/approve?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  rejectSettlement: (actorEmail, id, data) => {
    return APIKit.post(
      `/settlement/reject?actorEmail=${actorEmail}&id=${id}&rejectReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
};

//priority
export const priorityService = {
  getPriority: () => {
    return APIKit.get("/priority", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  updatePriority: (actorEmail, payload) => {
    return APIKit.patch(`/priority?actorEmail=${actorEmail}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};

// approver
export const approverService = {
  getApprover: () => {
    return APIKit.get(`/approver/pendingrules`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  acceptApproval: (actorEmail, id) => {
    return APIKit.post(
      `/approver/approverule?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  rejectApproval: (actorEmail, id, data) => {
    return APIKit.post(
      `approver/rejectrule?actorEmail=${actorEmail}&id=${id}&rejectionReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  getPriorityApprover: () => {
    return APIKit.get(`/approver/pendingpriority`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  approvePriority: (actorEmail, id) => {
    return APIKit.post(
      `/approver/approvepriority?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  rejectPriority: (actorEmail, id, data) => {
    return APIKit.post(
      `approver/rejectpriority?actorEmail=${actorEmail}&id=${id}&rejectionReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
};

// vendor
export const vendorService = {
  getAllVendors: () => {
    return APIKit.get("/merchants", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getAllActiveVendors: () => {
    return APIKit.get("/vendors", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getVendorById: (id) => {
    return APIKit.post(`/vendors${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  addVendors: (payload) => {
    return APIKit.post(`/vendors`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  getAllVendorsEdit: () => {
    return APIKit.get("/vendors/fetchpendingEdit", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  enableVendor: (actorEmail, vendorId) => {
    return APIKit.post(
      `/vendors/enable?actorEmail=${actorEmail}&vendorId=${vendorId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  approveEnabledVendor: (actorEmail, vendorId) => {
    return APIKit.post(
      `/vendors/approve/Enable?actorEmail=${actorEmail}&id=${vendorId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  rejectEnabledVendor: (actorEmail, vendorId, reason) => {
    return APIKit.post(
      `/vendors/reject/Enable?actorEmail=${actorEmail}&id=${vendorId}&rejectionReason=${reason}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  disableVendor: (actorEmail, vendorId) => {
    return APIKit.post(
      `/vendors/disable?actorEmail=${actorEmail}&vendorId=${vendorId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  approveDisabledVendor: (actorEmail, vendorId) => {
    return APIKit.post(
      `/vendors/approve/Disable?actorEmail=${actorEmail}&id=${vendorId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  rejectDisabledVendor: (actorEmail, vendorId, reason) => {
    return APIKit.post(
      `/vendors/reject/Disable?actorEmail=${actorEmail}&id=${vendorId}&rejectionReason=${reason}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
};

// transactions
export const transactionService = {
  getAllTransactions: (
    startDate,
    endDate,
    pageSize,
    pageNumber,
    statusFilter,
    vendorFilter,
    phoneNumberFilter,
    accountNumberFilter
  ) => {
    let url = "/transactions?";
    if (pageSize) {
      url += `pagesize=${pageSize}`;
    }
    if (pageNumber) {
      url += `&pagenumber=${pageNumber}`;
    }
    if (startDate && endDate) {
      url += `&from=${startDate}&to=${endDate}`;
    }
    if (statusFilter) {
      url += `&status=${statusFilter}`;
    }
    if (vendorFilter) {
      url += `&merchant=${vendorFilter}`;
    }
    if (phoneNumberFilter) {
      url += `&msisdn=${phoneNumberFilter}`;
    }
    if (accountNumberFilter) {
      url += `&request_id=${accountNumberFilter}`;
    }

    return APIKit.get(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getAllTransactionDetails: (id) => {
    return APIKit.get(`/transactions/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  doc: async(payload) => {
    return APIKit.post(
      `transactions/status-from-doc`,
      payload,
      {
        responseType: 'blob', // important to have response back as blob
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/formdata",
        },
      }
    );
  },
};

export const airtimeService = {
  getAirtime: (startDate, endDate) => {
    let url = "/airtime/stats?";

    if (startDate && endDate) {
      url += `dateFrom=${startDate}&dateTo=${endDate}`;
    }

    return APIKit.get(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};

export const userService = {
  getAllUsers: () => {
    return APIKit.get("/users", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getAllRoles: () => {
    return APIKit.get("/roles", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  getUserById: (id) => {
    return APIKit.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  addUser: (payload) => {
    return APIKit.post("/users", payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  addSystemUser: (payload) => {
    return APIKit.post("/account/registersystemuser", payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  addMerchants: (payload) => {
    return APIKit.post("/merchants", payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  updateUser: (payload) => {
    return APIKit.post("/user/edit", payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getNewUsersApproval: () => {
    return APIKit.get("/user/newusersapproval", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  approveUser: (actorEmail, id) => {
    return APIKit.post(
      `/user/approve?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  rejectUser: (actorEmail, id, data) => {
    return APIKit.post(
      `/user/reject?actorEmail=${actorEmail}&id=${id}&rejectionReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  getUpdateUsersApproval: () => {
    return APIKit.get("/user/updateusersapproval", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  approveEditedUser: (actorEmail, id) => {
    return APIKit.post(
      `/user/approveuseredit?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  rejectEditedUser: (actorEmail, id, data) => {
    return APIKit.post(
      `/user/rejectuseredit?actorEmail=${actorEmail}&id=${id}&rejectionReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  enableUser: (actorEmail, id) => {
    return APIKit.post(
      `/user/enable-revokedUser?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  revokeUser: (actorEmail, id, payload) => {
    return APIKit.post(
      `/user/revoke?actorEmail=${actorEmail}&id=${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  getRevokedUsersApproval: () => {
    return APIKit.get("/user/revokedusersapproval", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  approveRevokedUser: (actorEmail, id) => {
    return APIKit.post(
      `/user/approve-revoke?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },

  rejectRevokedUser: (actorEmail, id, data) => {
    return APIKit.post(
      `/user/reject-revoke?actorEmail=${actorEmail}&id=${id}&rejectionReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  logOutUser: (actorEmail) => {
    return APIKit.post(`/user/logout?actorEmail=${actorEmail}`, null, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  allRoles: () => {
    return APIKit.get("/roles", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};

export const auditService = {
  getUserActivity: () => {
    return APIKit.get("/audit", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};

export const settingsService = {
  getSettings: () => {
    return APIKit.get("/settings", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
  updateSettings: (id, payload) => {
    return APIKit.patch(`/Settings/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  getApprovalSettings: () => {
    return APIKit.get(`/settings/pending`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },

  approveSettings: (actorEmail, id) => {
    return APIKit.post(
      `/settings/approve?actorEmail=${actorEmail}&id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  rejectSettings: (actorEmail, id, data) => {
    return APIKit.post(
      `settings/reject?actorEmail=${actorEmail}&id=${id}&rejectionReason=${data}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  },
  //////doc
};

export const helpService = {
  postHelpMessage: (payload) => {
    return APIKit.post("email/help", payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};
