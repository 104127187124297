import React from 'react'

const Logout = () => {
  return (
  <div>
    <div>
        <h2 className='dash-header'>Logout</h2>
    </div>
  </div>
  )
}

export default Logout
