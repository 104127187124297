// Library methods
import React, { useState } from "react";
import moment from "moment";

import {
  Card,
  Typography,
  Box,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

//icons
import TimerIcon from "@mui/icons-material/Timer";

import "../styles/page-access.css";

const PageAccess = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const data = [
    {
      id: 1,
      name: "Dashboard",
      approver: true,
      userInitiator: true,
      userApprover: true,
      superAdmin: true,
      auditor: true,
      initiator: true,
    },
    {
      id: 2,
      name: "Configuration",
      approver: true,
      userInitiator: true,
      userApprover: true,
      superAdmin: true,
      auditor: true,
      initiator: true,
    },
    {
      id: 3,
      name: "Approver",
      approver: true,
      userInitiator: true,
      userApprover: true,
      superAdmin: true,
      auditor: true,
      initiator: true,
    },
    {
      id: 4,
      name: "Users",
      approver: true,
      userInitiator: true,
      userApprover: true,
      superAdmin: true,
      auditor: true,
      initiator: true,
    },
    {
      id: 5,
      name: "User Approver",
      approver: true,
      userInitiator: true,
      userApprover: true,
      superAdmin: true,
      auditor: true,
      initiator: true,
    },
  ];

  const handleSubmit = () => {
    const finalDataObj = {};

    Object.keys(checkedItems).forEach((key) => {
      const [parentKey, childKey] = key?.split("+");
      if (!finalDataObj[parentKey]) {
        finalDataObj[parentKey] = {};
      }

      finalDataObj[parentKey][childKey] = checkedItems[key];
    });
  };

  const handleCheckbox = (e) => {
    setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked });
  };
  return (
    <div className="dashboard-container">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h2 className="page-access-header-title">Page Access</h2>

            <div style={{ display: "flex" }}>
              <div className="time">
                <TimerIcon />
                <p>Time: {moment().format("h:mm:ss a")}</p>
              </div>
            </div>
          </div>
        </div>

        <Card
          sx={{
            borderRadius: "20px",
            width: "94%",
            marginTop: "35px",
            padding: "30px",
            paddingBottom: "60px",
            boxShadow: "none",
          }}
        >
          <Typography
            sx={{
              margin: "0px 30px",
              color: "#737373",
              fontSize: "20px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            All Pages
          </Typography>

          <Card
            sx={{
              width: "95%",
              padding: "30px",
              marginTop: "35px",
              boxShadow: "none",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "30px",
            }}
          >
            {data?.map((item) => (
              <Box
                key={item.id}
                sx={{
                  width: "426px",
                  padding: "30px",
                  borderRadius: "20px",
                  border: "1px solid #CFD4DD",
                  boxShadow: "none",
                }}
              >
                <FormControl>
                  <FormGroup
                    sx={{
                      color: "#255CFF",
                      fontSize: "18px",
                      fontFamily: "Open-Sans",
                      fontWeight: "700",
                    }}
                  >
                    {item?.name}
                  </FormGroup>
                  <FormGroup
                    sx={{
                      margin: "30px 0px 10px",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      rowGap: "30px",
                    }}
                  >
                    <div className="page-access-form-check-cont">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="initiator"
                            name={`${item?.id}+initiator`}
                            checked={
                              checkedItems[`${item?.id}+initiator`] ??
                              item?.initiator
                            }
                            onChange={(e) => handleCheckbox(e)}
                            sx={{
                              color: "#737373",
                              "&.Mui-checked": {
                                color: "#255CFF",
                              },
                            }}
                          />
                        }
                      />
                      <label className="page-access-form-check-label">
                        Initiator
                      </label>
                    </div>
                    <div className="page-access-form-check-cont">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="approver"
                            name={`${item?.id}+approver`}
                            checked={
                              checkedItems[`${item?.id}+approver`] ??
                              item?.approver
                            }
                            onChange={(e) => handleCheckbox(e)}
                            sx={{
                              color: "#737373",
                              "&.Mui-checked": {
                                color: "#255CFF",
                              },
                            }}
                          />
                        }
                      />
                      <label className="page-access-form-check-label">
                        Approver
                      </label>
                    </div>
                    <div className="page-access-form-check-cont">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="userInitiator"
                            name={`${item?.id}+userInitiator`}
                            checked={
                              checkedItems[`${item?.id}+userInitiator`] ??
                              item?.userInitiator
                            }
                            onChange={(e) => handleCheckbox(e)}
                            sx={{
                              color: "#737373",
                              "&.Mui-checked": {
                                color: "#255CFF",
                              },
                            }}
                          />
                        }
                      />
                      <label className="page-access-form-check-label">
                        User Initiator
                      </label>
                    </div>
                    <div className="page-access-form-check-cont">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="userApprover"
                            name={`${item?.id}+userApprover`}
                            checked={
                              checkedItems[`${item?.id}+userApprover`] ??
                              item?.userApprover
                            }
                            onChange={(e) => handleCheckbox(e)}
                            sx={{
                              color: "#737373",
                              "&.Mui-checked": {
                                color: "#255CFF",
                              },
                            }}
                          />
                        }
                      />
                      <label className="page-access-form-check-label">
                        User Approver
                      </label>
                    </div>
                    <div className="page-access-form-check-cont">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="auditor"
                            name={`${item?.id}+auditor`}
                            checked={
                              checkedItems[`${item?.id}+auditor`] ??
                              item?.auditor
                            }
                            onChange={(e) => handleCheckbox(e)}
                            sx={{
                              color: "#737373",
                              "&.Mui-checked": {
                                color: "#255CFF",
                              },
                            }}
                          />
                        }
                      />
                      <label className="page-access-form-check-label">
                        Auditor
                      </label>
                    </div>
                    <div className="page-access-form-check-cont">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="superAdminv"
                            name={`${item?.id}+superAdmin`}
                            checked={
                              checkedItems[`${item?.id}+superAdmin`] ??
                              item?.superAdmin
                            }
                            onChange={(e) => handleCheckbox(e)}
                            sx={{
                              color: "#737373",
                              "&.Mui-checked": {
                                color: "#255CFF",
                              },
                            }}
                          />
                        }
                      />
                      <label className="page-access-form-check-label">
                        Super Admin
                      </label>
                    </div>
                  </FormGroup>
                </FormControl>
              </Box>
            ))}
          </Card>
          <div className="page-access-btn-cont">
            <button style={{ background: "#9E9E9E" }} onClick={handleSubmit}>
              Save
            </button>
            <button style={{ background: "#255cff" }}>Reset</button>
          </div>
        </Card>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
    </div>
  );
};

export default PageAccess;
