// Library methods
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

// Components
import CardActiveSection from "./CardActiveSection";
import TrafficActiveSection from "./TrafficActiveSection";

// MUI Components
import TimerIcon from "@mui/icons-material/Timer";

// Utilities
import "../../../styles/dashboardContent.css";
import { dashboardService, transactionService } from "../../../utils/api";
import TransactionList from "./TransactionList";
// import NoTransaction from "./NoTransaction";

const DashboardContent = () => {
  const [cardData, setCardData] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [failedTransaction, setFailedTransaction] = useState({});
  const [successfulTransaction, setSuccessfulransaction] = useState({});
  const [fileUploadData, setFileUploadData] = useState({});
  const actor = JSON.parse(sessionStorage.getItem("user"));
  let today = moment().format("YYYY-MM-DD");
  const fetchCardData = async () => {
    try {
      const response = await dashboardService.getCardData(today);
      setCardData(response?.data);
      console.log(response?.data, "sxxxx");
      response?.data.map((el) => {
        if (el.Status === 4) {
          setSuccessfulransaction({ ...el });
        }
        if (el.Status === 2) {
          setFailedTransaction({ ...el });
        }
      });
    } catch (error) {
      return error;
    }
  };

  console.log(failedTransaction, "rotationnnnn");
  console.log(successfulTransaction, "dqwdwq");

  const fetchTransactions = useCallback(async () => {
    try {
      const response = await transactionService.getAllTransactions();
      setTransactionData(response?.data);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    fetchCardData();
  }, []);

  const transformText = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <div className="dashboard-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "94%",
          }}
        >
          <div>
            <h2 className="dash-header">Welcome {actor?.firstName}</h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss a")} </p>
            </div>
          </div>
        </div>
        <div>
          <CardActiveSection
            vendors={successfulTransaction?.Sum}
            users={successfulTransaction?.Count}
            networkrules={failedTransaction?.Sum}
            channelrules={failedTransaction?.Count}
          />
          <div
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "100px",
            }}
          >
            <button
              style={{
                padding: "10px",
                backgroundColor: "#255cff",
                color: "#fff",
                border: "none",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              Download Document
            </button>
          </div>

          {<TransactionList />}
          <TrafficActiveSection />
        </div>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
    </div>
  );
};

export default DashboardContent;
