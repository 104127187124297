import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userService, vendorService } from "../../utils/api";

import { Card } from "@mui/material";
const AddMerchants = ({ setOpenAddUser }) => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [roles, setRoles] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [merchantsValue, setMerchantsValue] = useState([]);
  const [userDetailsInput, setUserDetailsInput] = useState({
    description: "",
    name: "",
  });

  const validate = (values) => {
    // validate(formValues)
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!values.description) {
      errors.description = " description is required";
    }

    if (!values.name) {
      errors.name = " name is required";
    }

    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUserDetailsInput({ ...userDetailsInput, [name]: value });
  };

  const handleMerchants = (event) => {
    const { name, value } = event.target;

    setMerchantsValue(value?.name);
    setUserDetailsInput({ ...userDetailsInput, [name]: value.id });
  };

  const fetchRoles = async () => {
    try {
      const response = await userService.allRoles();

      setRoles(response.data);
    } catch (error) {
      return error;
    }
  };
  const fetchMerchants = async () => {
    try {
      const response = await vendorService.getAllVendors();

      setMerchants(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchMerchants();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(userDetailsInput));
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await userService.addMerchants(userDetailsInput);

        if (response?.status === 201) {
          toast.success("Merchants added successfully, waiting for approval.", {
            className: "toast-success",
            theme: "colored",
          });
          navigate("/admin/dashboard-active");
        } else {
          toast.error(response?.data?.message, {
            className: "toast-error",
            theme: "colored",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <Card
      sx={{
        background: "#F3F5FF",
        width: "100%",
        paddingTop: "10px",
        boxShadow: "none",
      }}
    >
      <div className="user-table-header">
        <div className="user-table-header-div">
          <h2 className="user-table-header-text"> Add New Merchants</h2>
        </div>
      </div>
      <form className="new-user-form" onSubmit={handleSubmit}>
        <div className="new-user-form-input-cont">
          <label htmlFor="name" className="new-user-form-label">
            Name
          </label>
          <div>
            <input
              type="text"
              name="name"
              value={userDetailsInput.name}
              onChange={handleChange}
              className="new-user-form-input"
              placeholder="Enter Name"
            />
            <p style={{ color: "red" }}>{formErrors.name}</p>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", columnGap: "39px" }}>
          <div className="new-user-form-input-cont">
            <label htmlFor="description" className="new-user-form-label">
              Description
            </label>
            <div>
              <input
                type="text"
                name="description"
                value={userDetailsInput.description}
                onChange={handleChange}
                className="new-user-form-input"
                placeholder="Enter Description"
              />
              <p style={{ color: "red" }}>{formErrors.description}</p>
            </div>
          </div>
        </div>

        <div className="new-user-form-button-footer">
          <p className="new-user-form-text">
            Please provide the correct Information required.
          </p>
          <div className="new-user-form-button-cont">
            <button
              type="button"
              onClick={() => setOpenAddUser(false)}
              style={{
                background: "#fff",
                color: "#255CFF",
                border: "1px solid #255CFF",
              }}
              className="new-user-form-button"
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{
                background: "#255CFF",
                color: "#ffffff",
              }}
              className="new-user-form-button"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default AddMerchants;
