import React, { useState, useEffect, useCallback } from "react";

import EditUser from "./EditUser";

// MUI Components
import { Card, Grid, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// Utilities
import { userService } from "../../utils/api";
import { toast } from "react-toastify";

const AllUsers = ({ setOpenAddUser }) => {
  const [data, setData] = useState([]);
  const [userById, setUserById] = useState([]);
  const [page, setPage] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openEditUser, setOpenEditUser] = useState(false);

  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  const fetchallUsers = useCallback(async () => {
    try {
      const response = await userService.getAllUsers();

      setData(response.data);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    fetchallUsers();
  }, [fetchallUsers]);

  const fetchUserById = async (id) => {
    try {
      const response = await userService.getUserById(id);
      setUserById(response.data);
      response.status === 200 && setOpenEditUser(true);
    } catch (error) {
      return error;
    }
  };
  // const handleEnableUser = async (email, id) => {
  //   try {
  //     const response = await userService.enableUser(email, id);

  //     if (response === undefined) {
  //       toast.error("Something went wrong.", {
  //         className: "toast-error",
  //         theme: "colored",
  //       });
  //     }
  //     if (response.status === 200) {
  //       toast.success("Enable request is successfully sent for approval.", {
  //         className: "toast-success",
  //         theme: "colored",
  //       });
  //     }
  //   } catch (error) {
  //     return error;
  //   }
  // };

  const handleRevokeUser = async (email, id) => {
    const payload = {
      id: id,
      email: email,
    };
    try {
      const response = await userService.revokeUser(email, id, payload);

      if (response?.status === 200) {
        toast.success("Revoke request is successfully sent for approval.", {
          className: "toast-success",
          theme: "colored",
        });
        fetchallUsers();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      return error;
    }
  };

  const rows = data?.map((item, idx) => {
    return {
      id: item?.id,
      name: `${item?.firstname} ${item?.lastname}`,
      phone: item?.phone,
      email: item?.email,
      role: item?.roles[0]?.role_name,
      date: item?.created_at?.substring(0, 10),
      action: (
        <div
          style={{
            width: "160px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {item?.disabled ? (
            <Button
              variant="contained"
              sx={{
                background: "#255CFF",
                textTransform: "capitalize",
                borderRadius: "10px",
                boxShadow: "none",
                fontFamily: "Open-Sans",
              }}
              onClick={() => fetchUserById(item?.id)}
              disabled={item?.status === "PENDING"}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                sx={{
                  background: "#255CFF",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  boxShadow: "none",
                  fontFamily: "Open-Sans",
                }}
                onClick={() => fetchUserById(item.id)}
                disabled={item?.status === "PENDING"}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#fff",
                  color: "#255CFF",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  border: "1px solid #255CFF",
                  boxSizing: "border-box",
                  boxShadow: "none",
                  fontFamily: "Open-Sans",
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                  "&:disabled": {
                    border: "none",
                  },
                }}
                onClick={() => {
                  handleRevokeUser(actorEmail, item.id);
                  fetchallUsers();
                }}
                disabled={item?.status === "PENDING"}
              >
                Revoke
              </Button>
            </>
          )}
        </div>
      ),
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {openEditUser ? (
        <EditUser
          userById={userById}
          setOpenEditUser={setOpenEditUser}
          fetchUsers={fetchallUsers}
        />
      ) : (
        <>
          <Card
            sx={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              width: "92%",
              padding: "30px",
              boxShadow: "none",
            }}
          >
            <div className="user-table-header">
              <div className="user-table-header-div">
                <h2 className="user-table-header-text"> All Users</h2>
                <button
                  type="button"
                  className="user-table-header-button"
                  onClick={() => setOpenAddUser(true)}
                >
                  <AddCircleOutlineIcon style={{ marginRight: "6px" }} />
                  Add User
                </button>
              </div>
            </div>
          </Card>
          <Card
            sx={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              width: "92%",
              padding: "30px",
              boxShadow: "none",
            }}
          >
            <Grid container flexDirection={"column"}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        Roles
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        Registration Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.id}
                          </TableCell>
                          <TableCell
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.username}
                          </TableCell>
                          <TableCell
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.role}
                          </TableCell>
                          <TableCell
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.date}
                          </TableCell>
                          <TableCell
                            sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                          >
                            {row.action}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Card>
        </>
      )}
    </>
  );
};

export default AllUsers;
