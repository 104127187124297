import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { userService, vendorService } from "../../utils/api";

import { Card } from "@mui/material";
const AddUser = ({ setOpenAddUser }) => {
  const [formErrors, setFormErrors] = useState({});
  const [roles, setRoles] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [merchantsValue, setMerchantsValue] = useState([]);
  const [userDetailsInput, setUserDetailsInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    merchant_id: "",
    date_of_birth: "",
    user_type: "",
  });

  const validate = (values) => {
    // validate(formValues)
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!values.phone) {
      errors.phone = " phone number is required";
    }

    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values.date_of_birth) {
      errors.date_of_birth = "Date of Birth name is required";
    }
    if (!values.user_type) {
      errors.user_type = "Please select a role for this user";
    }
    if (!values.password) {
      errors.password = "Please select a password for this user";
    }
    if (!values.merchant_id) {
      errors.merchant_id = "Please select a merchant  for this user";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUserDetailsInput({ ...userDetailsInput, [name]: value });
  };

  const handleMerchants = (event) => {
    const { name, value } = event.target;

    setMerchantsValue(value?.name);
    setUserDetailsInput({ ...userDetailsInput, [name]: value.id });
  };

  const fetchRoles = async () => {
    try {
      const response = await userService.allRoles();

      setRoles(response.data);
    } catch (error) {
      return error;
    }
  };
  const fetchMerchants = async () => {
    try {
      const response = await vendorService.getAllVendors();

      setMerchants(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchMerchants();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(userDetailsInput));
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await userService.addUser(userDetailsInput);

        if (response?.status === 200) {
          toast.success("User added successfully, waiting for approval.", {
            className: "toast-success",
            theme: "colored",
          });
        } else {
          toast.error(response?.data?.message, {
            className: "toast-error",
            theme: "colored",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <Card
      sx={{
        background: "#F3F5FF",
        width: "100%",
        paddingTop: "10px",
        boxShadow: "none",
      }}
    >
      <div className="user-table-header">
        <div className="user-table-header-div">
          <h2 className="user-table-header-text"> Add New User</h2>
        </div>
      </div>
      <form className="new-user-form" onSubmit={handleSubmit}>
        <div className="new-user-form-input-cont">
          <label htmlFor="phone" className="new-user-form-label">
            Phone Number
          </label>
          <div>
            <input
              type="text"
              name="phone"
              value={userDetailsInput.phone}
              onChange={handleChange}
              className="new-user-form-input"
              placeholder="Enter phone"
            />
            <p style={{ color: "red" }}>{formErrors.userName}</p>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", columnGap: "39px" }}>
          <div className="new-user-form-input-cont">
            <label htmlFor="firstName" className="new-user-form-label">
              First Name
            </label>
            <div>
              <input
                type="text"
                name="firstName"
                value={userDetailsInput.firstName}
                onChange={handleChange}
                className="new-user-form-input"
                placeholder="Enter first name"
              />
              <p style={{ color: "red" }}>{formErrors.firstName}</p>
            </div>
          </div>
          <div className="new-user-form-input-cont">
            <label htmlFor="lasttName" className="new-user-form-label">
              Last Name
            </label>
            <div>
              <input
                type="text"
                name="lastName"
                value={userDetailsInput.lastName}
                onChange={handleChange}
                className="new-user-form-input"
                placeholder="Enter last name"
              />
              <p style={{ color: "red" }}>{formErrors.lastName}</p>
            </div>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="date" className="new-user-form-label">
            Date of Birth
          </label>
          <div>
            <input
              type="date"
              name="date_of_birth"
              value={userDetailsInput.date_of_birth}
              onChange={handleChange}
              className="new-user-form-input"
              placeholder="Enter date of birth"
            />
            <p style={{ color: "red" }}>{formErrors.date_of_birth}</p>
          </div>
        </div>

        <div className="new-user-form-input-cont">
          <label htmlFor="email" className="new-user-form-label">
            Email
          </label>
          <div>
            <input
              type="email"
              name="email"
              value={userDetailsInput.email}
              onChange={handleChange}
              className="new-user-form-input"
              placeholder="Enter email address"
            />
            <p style={{ color: "red" }}>{formErrors.email}</p>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="password" className="new-user-form-label">
            Password
          </label>
          <div>
            <input
              type="text"
              name="password"
              value={userDetailsInput.password}
              onChange={handleChange}
              className="new-user-form-input"
              placeholder="Enter Password"
            />
            <p style={{ color: "red" }}>{formErrors.password}</p>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="firstName" className="new-user-form-label">
            Merchants
          </label>
          <div>
            <select
              name="merchant_id"
              id=""
              value={merchantsValue}
              onChange={handleMerchants}
              className="new-user-form-input"
            >
              <option selected>Select Merchant</option>
              {merchants?.map((item) => {
                return (
                  <option key={item?.id} value={item}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

            <p style={{ color: "red" }}>{formErrors.merchant_id}</p>
          </div>
        </div>
        <div className="new-user-form-input-cont">
          <label htmlFor="firstName" className="new-user-form-label">
            Role
          </label>
          <div>
            <select
              name="user_type"
              id=""
              value={userDetailsInput.user_type}
              onChange={handleChange}
              className="new-user-form-input"
            >
              <option selected>Select Role</option>
              {roles?.map((item) => {
                return (
                  <option key={item?.id} value={item?.name}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

            <p style={{ color: "red" }}>{formErrors.user_type}</p>
          </div>
        </div>

        <div className="new-user-form-button-footer">
          <p className="new-user-form-text">
            Please provide the correct Information required.
          </p>
          <div className="new-user-form-button-cont">
            <button
              type="button"
              onClick={() => setOpenAddUser(false)}
              style={{
                background: "#fff",
                color: "#255CFF",
                border: "1px solid #255CFF",
              }}
              className="new-user-form-button"
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{
                background: "#255CFF",
                color: "#ffffff",
              }}
              className="new-user-form-button"
            >
              Add User
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default AddUser;
