import React from 'react'
import ruleImg from '../../../assets/svg/ruleImg.svg';
import vendorsImg from '../../../assets/svg/vendorsImg.svg';
import speedImg from '../../../assets/svg/speedImg.svg';




const CardSection = () => {

    const cards = [
         {
            img: ruleImg,
            number: '0',
            title: 'Active Rules'
         },

         {
            img: vendorsImg,
            number: '0',
            title: 'Active Vendors'
         },

         {
            img: speedImg,
            number: '0',
            title: 'Internet Speed'
         }
    ]

  return (
    <div>
        <div className='card-bg'>
        <div   className='cards-container'>
            {cards.map((card, index) => (
                <div key={index} className='cards'>
                    <img  className='card-img' src={card.img} alt='icon images' />
                    <div className='card-text'>
                        <p className='card-num'>{card.number}</p>
                        <p className='card-title'>{card.title}</p>
                    </div>
                </div>
              
            ))}
            </div>
        </div>
    </div>
  )
}

export default CardSection
