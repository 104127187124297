import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { InboxOutlined } from "@ant-design/icons";
import { Card } from "@mui/material";
import { message, Upload } from "antd";
import {
  transactionService,
  userService,
  vendorService,
} from "../../utils/api";
const { Dragger } = Upload;

const AddDocument = () => {
  const [fileUploadData, setFileUploadData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    console.log(event, "ewfewfewfwsssoe", event.file);

    const uploadFormData = new FormData();
    uploadFormData.append("file", event.file.originFileObj);
    setFileUploadData(uploadFormData);
  };

  const downloadTransaction = async () => {
    setLoading(true);
    message.loading("document loading please wait.....", 2);
    try {
      const response = await transactionService.doc(fileUploadData);

      if(!response?.data)  return setLoading(false);

      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(file); // create a URL for the Blob
      const link = document.createElement("a"); // create a link element
      link.href = url; // set the href of the link to the Blob URL
      const now = new Date();

      const [Y, M, D, H,m] = [
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes()
      ]
      const title = `transactions_status_downloded_on_${Y}-${M}-${D}+${H}-${m}.xlsx`
      link.download = title; // set the filename and extension
      link.click();

      // Clean up by revoking the object URL
      window.URL.revokeObjectURL(url);
      message.success("downloaded successfully...");
      setLoading(false);      
    } catch (error) {
      // console.error(error, "errororooooo");
      setLoading(false);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,

    onChange(event) {
      handleChange(event);

      event.fileList.forEach((item) => {
        if (item.uid === event.file.uid) {
          event.file.status = "done";
        }
      });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Card
      sx={{
        background: "#F3F5FF",
        width: "100%",
        paddingTop: "10px",
        boxShadow: "none",
      }}
    >
      <div className="user-table-header">
        <div className="user-table-header-div">
          <h2 className="user-table-header-text"> Add New Document</h2>
        </div>
      </div>
      <div className="new-user-form" style={{ minHeight: "auto" }}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint" style={{marginTop: '24px'}}>
            Only an Excel (.xlsx) file  with transaction's <strong> internal reference</strong> on the last 
            column should be uploaded to have the transaction status for all matched references.
            {/* Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files. */}
          </p>
        </Dragger>

        <div className="new-user-form-button-footer">
          <p className="new-user-form-text">
            Please provide the correct Information required.
          </p>
          <div className="new-user-form-button-cont">
            <button
              type="button"
              style={{
                background: "#fff",
                color: "#255CFF",
                border: "1px solid #255CFF",
              }}
              className="new-user-form-button"
            >
              Go Back
            </button>
            <button
              disabled={loading}
              onClick={downloadTransaction}
              style={{
                background: "#255CFF",
                color: "#ffffff",
              }}
              className="new-user-form-button"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AddDocument;
