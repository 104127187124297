import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const VendorModal = ({
  details,
  onConfirmEnable,
  onConfirmDisable,
  setIsRejectOpen,
  onCancel,
}) => {
  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  return (
    <Grid container py={8} px={6} width={"400px"}>
      <Grid item width={"100%"}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#255cff",
            fontFamily: "Open-Sans",
          }}
        >
          VENDOR APPROVAL
        </Typography>

        <Box display={"flex"} justifyContent={"space-between"} my={1}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Modifier:
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.initiatorEmail}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Action
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.data}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Approval Status
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.approvalStatus}
          </Typography>
        </Box>

        {details?.rejectionReason !== null && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "36px",
                fontFamily: "Open-Sans",
              }}
            >
              Rejection Reason
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "36px",
                fontFamily: "Open-Sans",
              }}
            >
              {details?.rejectionReason}
            </Typography>
          </Box>
        )}

        {/* <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Date Created
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {details?.createdAt.substring(0, 10) ?? "-"}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Time Created
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {details?.createdAt.substring(11, 16) ?? "-"}
          </Typography>
        </Box> */}

        <Box
          py={2}
          display={details?.approvalStatus === "PENDING" ? "flex" : "none"}
          justifyContent={"space-around"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#fff",
              textTransform: "capitalize",
              color: "#255cff",
              fontFamily: "Open-Sans",
              border: "2px solid #255cff",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#B3B3B3",
              },
            }}
            onClick={() => {
              setIsRejectOpen(true);
              onCancel(false);
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#255cff",
              textTransform: "capitalize",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255cff",
              },
            }}
            onClick={() => {
              details.action === "ENABLE VENDOR"
                ? onConfirmEnable(actorEmail, details?.id)
                : onConfirmDisable(actorEmail, details?.id);
              onCancel(false);
            }}
          >
            Approve
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VendorModal;
