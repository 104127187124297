import "../styles/error.css";
import logo404 from "../assets/svg/logo404.svg";

const Error404 = () => {
  return (
    <div className="error-container">
      <img src={logo404} alt="Error 404" />
      <h2 className="error-header">Oops, Something went wrong</h2>
      <p className="error-sub-header">Error 404, page not found</p>
    </div>
  );
};

export default Error404;
