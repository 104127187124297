// Library methods
import React, { useState, useEffect, useCallback } from "react";

// Components

// MUI Components
import { Card, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

// Utilities
import { vendorService } from "../../utils/api";
import pendingIcon from "../../assets/svg/rules/pending.svg";
//import approvedIcon from "../../assets/svg/rules/approved.svg";
//import rejectedIcon from "../../assets/svg/rules/rejected.svg";
import CenterModal from "../modal/center";
import VendorModal from "./modals/VendorModal";
import VendorRejectionModal from "./modals/VendorRejectionModal";
import { toast } from "react-toastify";

const VendorApproval = () => {
  const [data, setData] = useState([]);
  const [rules, setRules] = useState([]);
  const [details, setDetails] = useState([]);
  const [requestPath, setRequestPath] = useState("PENDING");
  const [priorityOpen, setPriorityOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);

  //get all approvals
  const fetchAllApprovals = async () => {
    try {
      const response = await vendorService.getAllVendorsEdit();
      const sortedData = response?.data?.sort((a, b) => b.id - a.id);
      setData(sortedData);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    fetchAllApprovals();
  }, []);

  const handleRules = useCallback(
    (requestPath) => {
      const filteredRules = data?.filter(
        (item) => item?.approvalStatus === requestPath
      );
      setRules(filteredRules);
    },
    [data]
  );

  useEffect(() => {
    handleRules(requestPath);
  }, [handleRules, requestPath]);

  const pendingVendors = data?.filter(
    (item) => item?.approvalStatus === "PENDING"
  );
  //onst approvedVendors = data?.filter((item) => item?.status === "APPROVED");
  //const rejectedVendors = data?.filter((item) => item?.status === "REJECTED");

  const cards = [
    {
      img: pendingIcon,
      details: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "27px",
              fontWeight: "600",
              fontFamily: "Open-Sans",
            }}
          >
            {pendingVendors?.length}
          </Typography>
          <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans" }}>
            Awaiting Approval
          </Typography>
        </div>
      ),
      path: "PENDING",
    },

    // {
    //   img: approvedIcon,
    //   details: (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <Typography sx={{ fontSize: "27px", fontWeight: "600" , fontFamily: "Open-Sans",}}>
    //         {approvedVendors?.length}
    //       </Typography>
    //       <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans", }}>Approved</Typography>
    //     </div>
    //   ),
    //   path: "APPROVED",
    // },

    // {
    //   img: rejectedIcon,
    //   details: (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <Typography sx={{ fontSize: "27px", fontWeight: "600", fontFamily: "Open-Sans", }}>
    //         {rejectedVendors?.length}
    //       </Typography>
    //       <Typography sx={{ fontSize: "13px", fontFamily: "Open-Sans", }}>Rejected</Typography>
    //     </div>
    //   ),
    //   path: "REJECTED",
    // },
  ];

  //approve vendor enable
  const handleEnabledApprove = async (email, id) => {
    try {
      await vendorService.approveEnabledVendor(email, id).then((res) => {
        if (res?.status === 200) {
          toast.success("Vendor enabled successfully", {
            className: "toast-success",
            theme: "colored",
          });
          setPriorityOpen(false);
          window.location.reload();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  //reject vendor enable
  const handleEnabledReject = async (email, id, reason) => {
    try {
      await vendorService.rejectEnabledVendor(email, id, reason).then((res) => {
        if (res?.status === 200) {
          toast.success("Request is successfully rejected", {
            className: "toast-success",
            theme: "colored",
          });
          setPriorityOpen(false);
          handleRules(requestPath);
          window.location.reload();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  //approve vendor disable
  const handleDisabledApprove = async (email, id) => {
    try {
      await vendorService.approveDisabledVendor(email, id).then((res) => {
        if (res?.status === 200) {
          toast.success("Vendor disabled successfully", {
            className: "toast-success",
            theme: "colored",
          });
          setPriorityOpen(false);
          window.location.reload();
        } else {
          toast.error("Something went wrong", {
            className: "toast-error",
            theme: "colored",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  //reject vendor disable
  const handleDisabledReject = async (email, id, reason) => {
    try {
      await vendorService
        .rejectDisabledVendor(email, id, reason)
        .then((res) => {
          if (res?.status === 200) {
            toast.success("Request is successfully rejected", {
              className: "toast-success",
              theme: "colored",
            });
            setPriorityOpen(false);
            handleRules(requestPath);
            window.location.reload();
          } else {
            toast.error("Something went wrong", {
              className: "toast-error",
              theme: "colored",
            });
          }
        });
    } catch (error) {
      toast.error("Something went wrong", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const rows = rules?.map((item) => {
    return {
      email: item?.initiatorEmail,
      action: item?.action,
      vendorName: item?.vendorName,
      data: item?.data,
      date: `${item?.date?.substring(0, 10)} - ${item?.date?.substring(
        11,
        16
      )}`,
      approve: (
        <Button
          variant="contained"
          sx={{
            background: "#255cff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Open-Sans",
            "&:hover": {
              backgroundColor: "#255cff",
              color: "white",
              borderColor: "white",
            },
          }}
          onClick={() => {
            setDetails(item);
            setPriorityOpen(true);
          }}
        >
          View Details
        </Button>
      ),
    };
  });

  return (
    <Card
      sx={{
        background: "#f3f5ff",
        borderRadius: "5px",
        width: "98%",
        padding: "30px 0px",
        boxShadow: "none",
      }}
    >
      <Typography
        sx={{
          margin: "0px 30px",
          color: "#737373",
          fontSize: "20px",
          fontWeight: "600",
          fontFamily: "Open-Sans",
        }}
      >
        Vendor Approval Queue
      </Typography>

      <Grid
        container
        height={"224px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 40px"}
        my={4}
        backgroundColor={"#f3f5ff"}
      >
        {cards?.map((item, idx) => (
          <Grid item key={idx}>
            <Card
              sx={{
                width: "210px",
                height: "80px",
                padding: "30px 20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                borderRadius: "23px",
                cursor: "pointer",
                background: "#FFFFFF",
                color:
                  requestPath === item?.path && requestPath === "PENDING"
                    ? "#FF9515"
                    : requestPath === item?.path && requestPath === "APPROVED"
                    ? "#1CC218"
                    : requestPath === item?.path && requestPath === "REJECTED"
                    ? "#CC0707"
                    : "#000",
                border:
                  requestPath === item?.path && requestPath === "PENDING"
                    ? "1px solid #FF9515"
                    : requestPath === item?.path && requestPath === "APPROVED"
                    ? "1px solid #1CC218"
                    : requestPath === item?.path && requestPath === "REJECTED"
                    ? "1px solid #CC0707"
                    : "none",
              }}
              onClick={() => {
                setRequestPath(item?.path);
              }}
            >
              <div
                style={{
                  background: "#EEF0F4",
                  width: "62px",
                  height: "62px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                }}
              >
                <img src={item?.img} alt="approval icon" />
              </div>
              <Typography m={2} fontSize={"16px"}>
                {item?.details}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        flexDirection={"column"}
        backgroundColor={"#fff"}
        minHeight={"100px"}
        padding={"30px 0"}
        borderRadius={"20px"}
      >
        {
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    S/N
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Initiator Email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Vendor Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      fontFamily: "Open-Sans",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {idx + 1}
                    </TableCell>
                    <TableCell
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row.vendorName}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor:
                            row?.action === "ENABLE VENDOR"
                              ? "#1CC21826"
                              : "#CC070726",
                          color:
                            row?.action === "ENABLE VENDOR"
                              ? "#1CC218"
                              : "#CC0707",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        {row?.action}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: "#EEF0F4",
                          color: "#737373",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        {row?.date}
                      </div>
                    </TableCell>
                    <TableCell>{row.approve}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>

      <CenterModal
        isOpen={priorityOpen}
        toggleModal={() => {
          setPriorityOpen(!priorityOpen);
        }}
      >
        <VendorModal
          details={details}
          onConfirmEnable={handleEnabledApprove}
          onConfirmDisable={handleDisabledApprove}
          setIsRejectOpen={setIsRejectOpen}
          onCancel={setPriorityOpen}
        />
      </CenterModal>

      <CenterModal
        isOpen={isRejectOpen}
        toggleModal={() => {
          setIsRejectOpen(!isRejectOpen);
        }}
      >
        <VendorRejectionModal
          details={details}
          onRejectEnable={handleEnabledReject}
          onRejectDisable={handleDisabledReject}
          setIsRejectOpen={setIsRejectOpen}
        />
      </CenterModal>
    </Card>
  );
};

export default VendorApproval;
