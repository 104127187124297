import React, { useCallback, useEffect, useState } from "react";

import { DateRange } from "react-date-range";
import "react-date-range/dist/theme/default.css";
import "react-date-range/dist/styles.css";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// import SearchIcon from "@mui/icons-material/Search";

import exportIcon from "../../../assets/svg/dashboard/exportIcon.svg";
import { transactionService, vendorService } from "../../../utils/api";

import { formatPickerDate } from "../../../utils/helpers";
import CenterModal from "../../modal/center";
import TransactionDetailModal from "../../approval/modals/TransactionDetailModal";

import datePicker from "../../../assets/svg/dashboard/datePicker.svg";
import searchIcon from "../../../assets/svg/dashboard/search.svg";
import PaginationWrapper from "../../PaginationWrapper";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const TransactionList = () => {
  const [data, setData] = useState([]);
  const [transactionDataDetail, setTransactionDataDetail] = useState({});
  const [paginationData, setPaginationData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState(formatPickerDate(new Date()));
  const [endDate, setEndDate] = useState(formatPickerDate(new Date()));
  const [show, setShow] = useState(false);
  const [isTransactionOpen, setIsTransactionOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [vendorFilter, setVendorFilter] = useState("");
  const [accountNumberFilter, setAccountNumberFilter] = useState("");
  const [phoneNumberFilter, setPhoneNumberFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [exportData, setExportData] = useState([]);
  const [exportDataState, setExportDataState] = useState(false);

  const handleClose = () => setShow(false);

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      statusFilter: statusFilter,
    },
  ]);

  // pagination change
  const onPaginationChange = (event, value) => {
    setPageNumber(value);
  };

  const fetchTransactions = useCallback(async () => {
    try {
      const response = await transactionService.getAllTransactions(
        startDate,
        endDate,
        pageSize,
        pageNumber,
        statusFilter,
        vendorFilter,
        phoneNumberFilter,
        accountNumberFilter
      );

      if (response.status === 200) {
        setData(response?.data);
        if (response?.headers?.pagination) {
          setPaginationData(JSON.parse(response?.headers?.pagination));
        }
      }
    } catch (error) {
      return error;
    }
  }, [
    startDate,
    endDate,
    pageSize,
    pageNumber,
    statusFilter,
    accountNumberFilter,
    vendorFilter,
    phoneNumberFilter,
  ]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const fetchVendors = async () => {
    const response = await vendorService.getAllVendors();
    setVendors(response?.data);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchTransactionsToExport = useCallback(async () => {
    const pageSize = 100000;
    try {
      const response = await transactionService.getAllTransactions(
        startDate,
        endDate,
        pageSize,
        statusFilter,
        vendorFilter,
        phoneNumberFilter,
        accountNumberFilter
      );

      if (response.status === 200) {
        setExportData(response?.data);
      }
    } catch (error) {
      return error;
    }
  }, [
    startDate,
    endDate,
    statusFilter,
    accountNumberFilter,
    vendorFilter,
    phoneNumberFilter,
  ]);

  const handleXlsxExport = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToXlsx = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return exportToXlsx(apiData, fileName);
  };

  const nairaFormatter = (amount) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter?.format(amount);
  };

  const tableRows = data?.map((item, idx) => ({
    id: idx + 1,
    vendor: item?.vendorName,
    network: (
      <div
        style={{
          background:
            item?.networkProviderName === "MTN"
              ? "#FFD953"
              : item?.networkProviderName === "GLO"
              ? "#72F651"
              : item?.networkProviderName === "AIRTEL"
              ? "#FF543D"
              : "#3C8702",
          color:
            item?.networkProviderName === "MTN" ||
            item?.networkProviderName === "GLO"
              ? "#000"
              : "#fff",
          height: "25px",
          padding: "0px 5px",
          borderRadius: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item?.networkProviderName}
      </div>
    ),
    request_id: item?.request_id,
    phoneNo: item?.msisdn,
    amount: nairaFormatter(item?.amount),
    status: item?.status?.split(/(?=[A-Z])/)?.join(" "),
    processed: item?.processed ? "True" : "False",
    date: (
      <div
        style={{
          backgroundColor: "#EEF0F4",
          borderRadius: "30px",
          height: "25px",
          padding: "0px 5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >{`${item?.date?.substring(0, 10)} - ${item?.date?.substring(
        11,
        19
      )}`}</div>
    ),
    action: (
      <Button
        variant="contained"
        sx={{
          background: "#255cff",
          textTransform: "capitalize",
          fontFamily: "Open-Sans",
          "&:hover": {
            backgroundColor: "#255cff",
          },
        }}
        onClick={() => {
          setIsTransactionOpen(true);
          setTransactionDataDetail(item);
        }}
      >
        View Details
      </Button>
    ),
  }));

  return (
    <div className="transaction-container">
      <h1 className="transaction-header">Recent Transactions</h1>

      <div className="transaction-header-div">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "6px" }}>
            {/* <div className="transaction-header-filter">
              <input
                type="search"
                placeholder="Search"
                className="transaction-header-filter-input"
              />
              <div className="transaction-header-filter-icon">
                <img src={searchIcon} alt="search icon" />
              </div>
            </div> */}

            <div className="transaction-header-filter">
              <input
                type="search"
                placeholder="Req ID"
                value={accountNumberFilter}
                onChange={(e) => {
                  setAccountNumberFilter(e.target.value);
                }}
                className="transaction-header-filter-input"
              />
              <div className="transaction-header-filter-icon">
                <img src={searchIcon} alt="search icon" />
              </div>
            </div>
            <div className="transaction-header-filter">
              <input
                type="search"
                placeholder="Phone No"
                value={phoneNumberFilter}
                onChange={(e) => {
                  setPhoneNumberFilter(e.target.value);
                }}
                className="transaction-header-filter-input"
              />
              <div className="transaction-header-filter-icon">
                <img src={searchIcon} alt="search icon" />
              </div>
            </div>
            <div className="transaction-header-filter">
              <input
                type="file"
                placeholder="Phone No"
                value={phoneNumberFilter}
                onChange={(e) => {
                  setPhoneNumberFilter(e.target.value);
                }}
                accept=".xls,.xlsx"
                className="transaction-header-filter-input"
              />
            </div>
            {/* <select
              name="statusFilter"
              id=""
              className="transaction-header-status"
              onChange={(e) => {
                setVendorFilter(e.target.value);
              }}
              value={vendorFilter}
            >
              <option value="">All</option>
              {vendors?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select> */}
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <select
              name="statusFilter"
              id=""
              className="transaction-header-status"
              onChange={(e) => {
                setStatusFilter(e.target.value);
              }}
              value={statusFilter}
            >
              <option value="">All</option>
              <option value="3">Pending</option>
              <option value="4">Successful</option>
              <option value="2">Failed</option>
              <option value="1">New</option>
            </select>

            <div
              className="transaction-header-div-date"
              onClick={() => setShow(true)}
            >
              {startDate !== formatPickerDate(new Date()) &&
              endDate !== formatPickerDate(new Date()) ? (
                <p>
                  {`${months[new Date(startDate)?.getMonth()]?.substring(
                    0,
                    3
                  )} ${new Date(startDate)?.getDate()} - 
                  ${months[new Date(endDate)?.getMonth()]?.substring(
                    0,
                    3
                  )} ${new Date(endDate)?.getDate()}
                `}
                </p>
              ) : (
                <p>{`Today: ${months[new Date()?.getMonth()]?.substring(
                  0,
                  3
                )} ${new Date()?.getDate()}`}</p>
              )}
              <div className="transaction-date-icon">
                <img src={datePicker} alt="date icon" />
              </div>
            </div>
            <div style={{ position: "relative" }}>
              {data?.length > 0 && (
                <div
                  className="transaction-header-div-share"
                  onClick={() => {
                    fetchTransactionsToExport();
                    setExportDataState(!exportDataState);
                  }}
                >
                  <img src={exportIcon} alt="download" />
                </div>
              )}

              <div
                className="transaction-header-div-share-cont"
                style={{ display: exportDataState ? "block" : "none" }}
              >
                {exportData?.length > 0 && (
                  <CSVLink
                    data={exportData}
                    filename={`transaction-data.csv`}
                    onClick={() => setExportDataState(!exportDataState)}
                    className="transaction-header-div-share-content"
                  >
                    csv
                  </CSVLink>
                )}
                {exportData?.length > 0 && (
                  <p
                    onClick={(e) => {
                      handleXlsxExport(exportData, "transaction-data");
                      setExportDataState(!exportDataState);
                    }}
                    className="transaction-header-div-share-content"
                  >
                    xlsx
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", border: "1px" }}>
        <TableContainer>
          <Table
            aria-label="simple table"
            sx={{ width: "100%", border: "1px" }}
          >
            <TableHead className="transaction-table-header">
              <TableRow className="transaction-table-content-data">
                <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Network</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Phone No</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Request ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                color: "#000000",
              }}
              className="transaction-table-content"
            >
              {tableRows?.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.network}</TableCell>
                  <TableCell>{row.phoneNo}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.request_id}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.action}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            width: "100%",
            padding: "0 10px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "35px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#737373",
                paddingRight: "10px",
                fontSize: "14px",
                fontFamily: "Open-Sans",
              }}
            >
              Show
            </p>
            <select
              name=""
              id=""
              className="transaction-dropdown"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <p
              style={{
                color: "#737373",
                paddingLeft: "13px",
                fontSize: "14px",
                fontFamily: "Open-Sans",
              }}
            >
              entries
            </p>
          </div>

          <div className="transaction-table-pagination-container">
            <PaginationWrapper
              page={pageNumber}
              count={paginationData?.totalPages}
              onChange={onPaginationChange}
            />
          </div>
        </div>
      </div>

      <CenterModal
        isOpen={isTransactionOpen}
        toggleModal={() => {
          setIsTransactionOpen(!isTransactionOpen);
        }}
      >
        <TransactionDetailModal
          nairaFormatter={nairaFormatter}
          details={transactionDataDetail}
          onCancel={setIsTransactionOpen}
        />
      </CenterModal>
      <CenterModal
        isOpen={show}
        toggleModal={() => {
          setShow(!show);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setStartDate(formatPickerDate(item.selection.startDate));
              setEndDate(formatPickerDate(item.selection.endDate));
              setState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
            rangeColors={["#255CFF"]}
          />
          <button
            style={{
              color: "white",
              padding: "7px 25px",
              textAlign: "center",
              border: "1px solid #255CFF",
              background: "#255CFF",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Proceed
          </button>
        </div>
      </CenterModal>
    </div>
  );
};

export default TransactionList;
