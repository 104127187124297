import React from "react";
import "../../../styles/dashboardContent.css";
import SwitchConnect from "../../switchComponents/SwitchConnect";
import TimerIcon from "@mui/icons-material/Timer";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CardSection from "../emptyState/CardSection";
import RuleSection from "./RuleSection";
import TrafficSection from "./TrafficSection";
import moment from "moment";
// import {Outlet} from "react-router-dom";

const DashboardContent = () => {
  return (
    <div className="dashboard-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "94%",
        }}
      >
        <div>
          <h2 className="dash-header">Welcome Admin</h2>
        </div>
        <div style={{ display: "flex", columnGap: "20px" }}>
          <div>
            <SwitchConnect />
          </div>
          <div className="time">
            <TimerIcon />
            <p>Time: {moment().format("h:mm:ss a")} </p>
          </div>
          <div>
            <Box sx={{ color: "action.active", marginTop: "4px" }}>
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=""
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </Box>
          </div>
        </div>
      </div>

      <div>
        <CardSection />

        <RuleSection />
        <TrafficSection />
      </div>
    </div>
  );
};

export default DashboardContent;
