import React from 'react';
import { Card } from '@mui/material';
import Chart from './Chart';


const TrafficSection = () => {
    const trafficBtns = [
        {
            nameOfTheWeek : 'Weekly'
        },

        {
            nameOfTheWeek : 'Monthly'
        },

        {
            nameOfTheWeek : 'Yearly'
        }
    ]
  return (
    <div style={{width: '94%'}}>
       <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '69px', marginBottom: '26px' }}>
        <div>
        <h2 className='dash-header'>Traffic Statistics</h2>
        </div>

        <div >
            <input className='date-input' type="date" placeholder='01 - 28 Feb' />
        </div>
     
        <div  style={{ display: 'flex', columnGap: '15px' }}>
            {trafficBtns.map((btn, index) => (
                <div activeclassname='traffic-btn-active' key={index}>
                 <button type='submit' className='traffic-btn'>
                 {btn.nameOfTheWeek}
                </button>
              </div>
           
            ))}   
        </div>
      
     </div>

     <Card className='chart-card'>
        <div className='chart-header'>
            <p>Total Received : 40GB</p>
             <p>Total Sent : 20GB</p>
        </div>
        <div style={{marginTop: '3rem'}}>
        <Chart />
        </div>
     </Card>
    </div>
  )
}

export default TrafficSection
