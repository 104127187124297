import * as React from "react";

import TimerIcon from "@mui/icons-material/Timer";
import Box from "@mui/material/Box";
import "../../styles/ruleConfig.css";
import "../../styles/vendor.css";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RuleConfigSection from "./RuleConfigSection";
import VendorsList from "./VendorSection/VendorsList";
import AddNewVendor from "./VendorSection/AddNewVendor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RulesSection = () => {
  const [value, setValue] = React.useState(0);
  const [newVendorState, setNewVendorState] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dashboard-container">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h2 className="dash-header">Add Routing Rule</h2>

            <div style={{ display: "flex" }}>
              <div className="time">
                <TimerIcon />
                <p>Time: {moment().format("h:mm:ss a")}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="ruleConfig-header">
            Configure routing rules to automatically allow system detect
            customer actions by enabling the set configured rules
          </p>
        </div>

        <div className="ruleConfig-tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: { backgroundColor: "#255cff", height: 4 },
            }}
            sx={{
              width: "90%",
              margin: "0 auto",
              height: "72px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              "& button:active": { color: "#255cff" },
              "& button.Mui-selected": { color: "#255cff" },
            }}
          >
            <Tab
              label="Rule Configuration"
              {...a11yProps(0)}
              sx={{
                fontSize: "20px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />
            <Tab
              label="Vendors"
              {...a11yProps(1)}
              sx={{
                fontSize: "20px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />
          </Tabs>
        </div>

        <Box sx={{ width: "98%" }}>
          <TabPanel value={value} index={0}>
            <RuleConfigSection />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div style={{ diplay: "flex", justifyContent: "space-between" }}>
              <button
                type="button"
                className="btn-add-vendor"
                style={{
                  marginTop: "45px",
                  marginBottom: "26px",
                  width: "18%",
                }}
                onClick={() => setNewVendorState((prev) => !prev)}
              >
                <AddCircleOutlineIcon style={{ marginRight: "6px" }} />
                <div>ADD NEW VENDOR</div>
              </button>
            </div>

            {newVendorState && (
              <AddNewVendor setNewVendorState={setNewVendorState} />
            )}
            <VendorsList />
          </TabPanel>
        </Box>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
    </div>
  );
};

export default RulesSection;
