import React, { useState } from "react";
import TimerIcon from "@mui/icons-material/Timer";

import "../../styles/ruleConfig.css";
import moment from "moment";

import { Card } from "@mui/material";
import "../../styles/help.css";
import { helpService } from "../../utils/api";
import { toast } from "react-toastify";

const Help = () => {
  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setformErrors] = useState({});

  const handleHelpChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!values.fullName) {
      errors.fullName = "Full name is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleHelpSubmit = async (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await helpService.postHelpMessage(formValues);
        if (response?.status === 200) {
          toast.success(
            "Thank you for reaching out, we'll get back to you as soon as possible.",
            {
              className: "toast-success",
              theme: "colored",
            }
          );
          setFormValues(initialValues);
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      } catch (error) {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    }
  };
  return (
    <div className="dashboard-container">
      <div style={{ width: "94%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h2 className="dash-header">Help & Support</h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss A")}</p>
            </div>
          </div>
        </div>

        <div>
          <p className="ruleConfig-header">
            Contact us now to get assistance regarding any issue. We are
            available 24/7
          </p>
        </div>

        <Card
          sx={{
            height: "100%",
            width: "100%",
            paddingTop: "39px",
            paddingBottom: "39px",
            marginTop: "48px",
            boxShadow: "none",
            borderRadius: "20px",
          }}
        >
          <div className="help-container">
            <h2 className="help-header">Got questions? Let us help!</h2>
            <form onSubmit={handleHelpSubmit}>
              <div className="help-form">
                <div>
                  <label className="help-label" htmlFor="fullName">
                    Full Name
                  </label>
                </div>
                <div>
                  <input
                    className="help-input"
                    name="fullName"
                    type="text"
                    placeholder="Enter your full name here"
                    onChange={handleHelpChange}
                    value={formValues.fullName}
                  />
                  <p style={{ color: "red", paddingTop: "-40px" }}>
                    {formErrors.fullName}
                  </p>
                </div>
              </div>

              <div className="help-form">
                <div>
                  <label className="help-label" htmlFor="email">
                    Email
                  </label>
                </div>
                <div>
                  <input
                    className="help-input"
                    name="email"
                    type="email"
                    placeholder="Enter your Email here"
                    onChange={handleHelpChange}
                    value={formValues.email}
                  />
                  <p style={{ color: "red", paddingTop: "-40px" }}>
                    {formErrors.email}
                  </p>
                </div>
              </div>

              <div className="help-form">
                <div>
                  <label className="help-label" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                </div>
                <div>
                  <input
                    className="help-input"
                    name="phoneNumber"
                    type="number"
                    placeholder="Enter your Phone number here"
                    onChange={handleHelpChange}
                    value={formValues.phoneNumber}
                  />
                  <p style={{ color: "red", paddingTop: "-40px" }}>
                    {formErrors.phoneNumber}
                  </p>
                </div>
              </div>

              <div className="help-form">
                <div>
                  <label className="help-label" htmlFor="message">
                    Message
                  </label>
                </div>
                <div>
                  <textarea
                    className="help-input-textarea"
                    name="message"
                    type="text"
                    placeholder="Type here...."
                    onChange={handleHelpChange}
                    value={formValues.message}
                  />
                  <p style={{ color: "red", paddingTop: "-40px" }}>
                    {formErrors.message}
                  </p>
                </div>
              </div>
              <div className="help-btn-container">
                <button className="help-btn-text" type="submit">
                  Submit Now
                </button>
              </div>
            </form>
          </div>
        </Card>
        <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway</p>
      </div>
    </div>
  );
};

export default Help;
