import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

function Idle({ cancel }) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          window.location = "/auth/login";
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <Grid
      container
      sx={{ flexDirection: "column", textAlign: "center", p: 2, width: 500 }}
    >
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 600 }}>
        Idle Session
      </Typography>
      <form>
        <Grid>
          <div>
            <Typography sx={{ pt: 1 }}>
              You have been inactive for 10mins and are about to be logged out,
              do you still want to remain logged in?
            </Typography>
            <Typography
              sx={{
                color: "#255cff",
                fontWeight: 600,
                fontSize: "1.2rem",
                py: 1,
              }}
            >
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
          </div>
        </Grid>
      </form>
      <Grid className="myModal_main-widget-action">
        {/* <button className="cancel">Cancel</button> */}
        <button
          style={{
            background: "#255cff",
            color: "#FFF",
            padding: "10px",
            borderRadius: "5px",
            border: "none",
            fontWeight: 500,
            cursor: "pointer",
          }}
          onClick={cancel}
        >
          Stay Logged In
        </button>
      </Grid>
    </Grid>
  );
}

export default Idle;
