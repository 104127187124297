import React, { useState, useEffect, useCallback } from "react";
import { Card } from "@mui/material";
import TransactionStatistics from "./TransactionStatistics";
import { DateRange } from "react-date-range";
import "react-date-range/dist/theme/default.css";
import "react-date-range/dist/styles.css";

import { formatPickerDate } from "../../../utils/helpers";
import CenterModal from "../../modal/center";

import datePicker from "../../../assets/svg/dashboard/datePicker.svg";

import { airtimeService } from "../../../utils/api";

const TrafficSection = () => {
  const [airtimeData, setAirtimeData] = useState([]);
  const [startDate, setStartDate] = useState(formatPickerDate(new Date()));
  const [endDate, setEndDate] = useState(formatPickerDate(new Date()));
  const [show, setShow] = useState(false);
  const [applyFilter, setApplyFilter] = useState(null);

  const handleClose = () => setShow(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const trafficBtns = [
    {
      nameOfTheWeek: "Last 8 Days",
    },

    // {
    //   nameOfTheWeek: "Monthly",
    // },

    // {
    //   nameOfTheWeek: "Yearly",
    // },
  ];

  const filerCondition = useCallback(() => {
    if (
      startDate !== formatPickerDate(new Date()) &&
      endDate !== formatPickerDate(new Date())
    ) {
      setApplyFilter(true);
    } else {
      setApplyFilter(null);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    filerCondition();
  }, [filerCondition]);

  const getAirtime = useCallback(async () => {
    try {
      const response = await airtimeService.getAirtime(
        applyFilter && startDate,
        endDate
      );
      setAirtimeData(response.data);
    } catch (error) {
      return error;
    }
  }, [startDate, endDate, applyFilter]);

  useEffect(() => {
    getAirtime();
  }, [getAirtime]);

  const successfulTransaction = airtimeData?.map((item) => item.successful);
  let totalSuccessful = 0;
  for (let i of successfulTransaction) {
    totalSuccessful += i;
  }

  const failedTransaction = airtimeData?.map((item) => item.failed);
  let totalFailed = 0;
  for (let i of failedTransaction) {
    totalFailed += i;
  }

  const nairaFormatter = (amount) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter?.format(amount);
  };

  return (
    <div style={{ width: "94%" }}>
      <Card
        className="chart-card"
        sx={{ boxShadow: "none", borderRadius: "20px" }}
      >
        <div
          style={{
            margin: "69px 60px 46px 40px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h2 className="dash-header">Transaction Statistics</h2>
          </div>

          {/* <div>
          <input className="date-input" type="date" placeholder="01 - 28 Feb" />
        </div> */}
          <div
            className="transaction-header-div-date"
            onClick={() => setShow(true)}
          >
            {startDate !== formatPickerDate(new Date()) &&
            endDate !== formatPickerDate(new Date()) ? (
              <p>
                {`${months[new Date(startDate)?.getMonth()]?.substring(
                  0,
                  3
                )} ${new Date(startDate)?.getDate()} - 
                ${months[new Date(endDate)?.getMonth()]?.substring(
                  0,
                  3
                )} ${new Date(endDate)?.getDate()}
                `}
              </p>
            ) : (
              <p>{`Today: ${months[new Date()?.getMonth()]?.substring(
                0,
                3
              )} ${new Date()?.getDate()}`}</p>
            )}
            <div className="transaction-date-icon">
              <img src={datePicker} alt="date icon" />
            </div>
          </div>

          <div style={{ display: "flex", columnGap: "15px" }}>
            {trafficBtns.map((btn, index) => (
              <div activeclassname="traffic-btn-active" key={index}>
                <button
                  type="submit"
                  className="traffic-btn"
                  onClick={() => {
                    setApplyFilter(null);
                    setStartDate(formatPickerDate(new Date()));
                    setEndDate(formatPickerDate(new Date()));
                    getAirtime();
                  }}
                >
                  {btn.nameOfTheWeek}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="chart-header">
          <p>Total(Successful): {nairaFormatter(totalSuccessful)}</p>
          <p>Total(Failed): {nairaFormatter(totalFailed)} </p>
        </div>
        <div style={{ marginTop: "3rem" }}>
          <TransactionStatistics airtimeData={airtimeData} />
        </div>
      </Card>
      <CenterModal
        isOpen={show}
        toggleModal={() => {
          setShow(!show);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setStartDate(formatPickerDate(item.selection.startDate));
              setEndDate(formatPickerDate(item.selection.endDate));
              setState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
            rangeColors={["#255CFF"]}
          />
          <button
            style={{
              color: "white",
              padding: "7px 25px",
              textAlign: "center",
              border: "1px solid #255CFF",
              background: "#255CFF",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Proceed
          </button>
        </div>
      </CenterModal>
    </div>
  );
};

export default TrafficSection;
