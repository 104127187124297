import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useUser } from "../../utils/hooks/redux.hook";

const SettlementDetail = ({
  details,
  onConfirm,
  handleArchive,
  setIsRejectOpen,
  onCancel,
  type,
}) => {
  const { admin } = useUser();

  const mappedArray = Object?.entries(details)?.map(([key, value]) => {
    return { key: key, value: value };
  });

  return (
    <Grid container py={1} px={6} width={"500px"}>
      <Grid item width={"100%"}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "36px",
            color: "#255cff",
            textTransform: "uppercase",
          }}
        >
          {`${type} SETTLEMENT`}
        </Typography>

        {mappedArray?.map((item) => (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{ fontSize: "16px", lineHeight: "24px" }}>
              {item?.key}:
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "24px" }}>
              {item?.value === null
                ? "-"
                : item?.value === true
                ? "true"
                : item?.value === false
                ? "false"
                : item?.value}
            </Typography>
          </Box>
        ))}

        <Box py={2} display={"flex"} justifyContent={"space-around"}>
          <Button
            variant="contained"
            sx={{
              background: "#fff",
              textTransform: "capitalize",
              color: "#255cff",
              border: "2px solid #255cff",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#B3B3B3",
              },
            }}
            onClick={() => {
              if (type === "approve") {
                setIsRejectOpen(true);
              }

              if (type !== "approve") {
                handleArchive(admin?.admin?.email, details?.id);
              }
              onCancel(false);
            }}
          >
            {type === "approve" ? "Reject" : "Archive"}
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#255cff",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#255cff",
              },
            }}
            onClick={() => {
              onConfirm(admin?.admin?.email, details?.id);
              onCancel(false);
            }}
          >
            {type}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SettlementDetail;
